import jsIcon from '../assets/images/tech-icons/javascript.svg';
import cSharpIcon from '../assets/images/tech-icons/csharp.svg';
import rubyIcon from '../assets/images/tech-icons/ruby.svg';
import sqlIcon from '../assets/images/tech-icons/sql.svg';

import reactIcon from '../assets/images/tech-icons/react.svg';
import angularIcon from '../assets/images/tech-icons/angular.svg';
import jqueryIcon from '../assets/images/tech-icons/jquery.svg';
import typescriptIcon from '../assets/images/tech-icons/typescript.svg';
import htmlIcon from '../assets/images/tech-icons/html.svg';
import cssIcon from '../assets/images/tech-icons/css.svg';
import bootstrapIcon from '../assets/images/tech-icons/bootstrap.svg';
import materializeIcon from '../assets/images/tech-icons/materialize.svg';
import fluentUIIcon from '../assets/images/tech-icons/fluent-ui.png';

import nodeJsIcon from '../assets/images/tech-icons/nodejs.svg';
import expressJsIcon from '../assets/images/tech-icons/expressjs.svg';
import dotNetIcon from '../assets/images/tech-icons/dotnet.png';
import dotNetCoreIcon from '../assets/images/tech-icons/dotnetcore.svg';
import railsIcon from '../assets/images/tech-icons/rubyonrails.svg';

import mongoDbIcon from '../assets/images/tech-icons/mongodb.svg';
import sqlServerIcon from '../assets/images/tech-icons/sqlserver.svg';
import postgresqlIcon from '../assets/images/tech-icons/postgresql.svg';

import azureIcon from '../assets/images/tech-icons/azure.svg';

import umbracoIcon from '../assets/images/tech-icons/umbraco.svg';
import contensisIcon from '../assets/images/tech-icons/contensis.png';

import mochaIcon from '../assets/images/tech-icons/mocha.svg';
import chaiIcon from '../assets/images/tech-icons/chai.png';
import seleniumIcon from '../assets/images/tech-icons/selenium.svg';
import nUnitIcon from '../assets/images/tech-icons/nunit.png';

import gitIcon from '../assets/images/tech-icons/git.png';
import gitHubIcon from '../assets/images/tech-icons/github.svg';

const icons = {
    angular     : angularIcon,
    azure       : azureIcon,
    bootstrap   : bootstrapIcon,
    chai        : chaiIcon,
    contensis   : contensisIcon,
    cSharp      : cSharpIcon,
    css         : cssIcon,
    dotNet      : dotNetIcon,
    dotNetCore  : dotNetCoreIcon,
    expressJs   : expressJsIcon,
    fluentUI    : fluentUIIcon,
    git         : gitIcon,
    gitHub      : gitHubIcon,
    html        : htmlIcon,
    javaScript  : jsIcon,
    jQuery      : jqueryIcon,
    materialize : materializeIcon,
    nodeJs      : nodeJsIcon,
    mongoDB     : mongoDbIcon,
    mocha       : mochaIcon,
    nUnit       : nUnitIcon,
    postgreSQL  : postgresqlIcon,
    react       : reactIcon,
    ruby        : rubyIcon,
    rubyOnRails : railsIcon,
    sql         : sqlIcon,
    sqlServer   : sqlServerIcon,
    selenium    : seleniumIcon,
    typeScript  : typescriptIcon,
    umbraco     : umbracoIcon,
};

export default icons;