import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Toolbar.module.scss';
import Logo from '../../Logo/Logo';
import NavItems from '../NavItems/NavItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const Toolbar = (props) => {
    const [scrollMilestone, setScrollMilestone] = useState(null);

    let toolbarClasses = [classes.Toolbar];
    const transitionalToolbarThemes = ['homepage', 'journey', 'skills', 'projects'];

    if (!props.theme) {
        toolbarClasses.push(classes.Solid);
    }

    const addScrollListener = () => {
        window.addEventListener('scroll', getScrollPositions);
        // window.addEventListener('wheel', getScrollPositions);
        
        return () => {
            // window.removeEventListener('wheel', getScrollPositions);
            window.removeEventListener('scroll', getScrollPositions);
        };
    };
    
    const getScrollPositions = () => {
        const scrollPos = window.scrollY;

        if (scrollPos > 100 && scrollMilestone === 100) { return; }
        else if (scrollPos >= 100 && scrollMilestone !== 100) { setScrollMilestone(100); }
        else if (scrollPos >= 90 && scrollPos < 100 && scrollMilestone !== 90) { setScrollMilestone(90); }
        else if (scrollPos >= 80 && scrollPos < 90 && scrollMilestone !== 80) { setScrollMilestone(80); }
        else if (scrollPos >= 70 && scrollPos < 80 && scrollMilestone !== 70) { setScrollMilestone(70); }
        else if (scrollPos >= 60 && scrollPos < 70 && scrollMilestone !== 60) { setScrollMilestone(60); }
        else if (scrollPos >= 50 && scrollPos < 60 && scrollMilestone !== 50) { setScrollMilestone(50); }
        else if (scrollPos >= 40 && scrollPos < 50 && scrollMilestone !== 40) { setScrollMilestone(40); }
        else if (scrollPos >= 30 && scrollPos < 40 && scrollMilestone !== 30) { setScrollMilestone(30); }
        else if (scrollPos >= 20 && scrollPos < 30 && scrollMilestone !== 20) { setScrollMilestone(20); }
        else if (scrollPos >= 10 && scrollPos < 20 && scrollMilestone !== 10) { setScrollMilestone(10); }
        else if (scrollPos < 10 && scrollMilestone !== 0) { setScrollMilestone(0); }
    }

    if (transitionalToolbarThemes.indexOf(props.theme) > -1) {
        switch (scrollMilestone) {
            case 100: toolbarClasses.push(classes.Solid); break;
            case 90: toolbarClasses.push(classes.Transition90); break;
            case 80: toolbarClasses.push(classes.Transition80); break;
            case 70: toolbarClasses.push(classes.Transition70); break;
            case 60: toolbarClasses.push(classes.Transition60); break;
            case 50: toolbarClasses.push(classes.Transition50); break;
            case 40: toolbarClasses.push(classes.Transition40); break;
            case 30: toolbarClasses.push(classes.Transition30); break;
            case 20: toolbarClasses.push(classes.Transition20); break;
            case 10: toolbarClasses.push(classes.Transition10); break;
            default: toolbarClasses = [classes.Toolbar]; break;
        }
    }

    useEffect(addScrollListener, []);

    return (
        <header className={toolbarClasses.join(' ')}>
            <div className={classes.Content}>
                <div className={classes.Section1}>
                    <div className={classes.Logo} onClick={props.pageChanged}>
                        <NavLink to="/">
                            <Logo />
                        </NavLink>
                    </div>
                </div>
                <div className={classes.Section2}></div>
                <div className={classes.Section3}>
                    <nav className={classes.DesktopOnly}>
                        <NavItems
                            toolbarTheme={props.theme}
                            scrollMilestone={scrollMilestone}
                            pageChanged={props.pageChanged}/>
                    </nav>
                    <DrawerToggle
                        toolbarTheme={props.theme}
                        scrollMilestone={scrollMilestone}
                        pageChanged={props.pageChanged}
                        clicked={props.drawerToggleClicked} />
                </div>
            </div>
        </header>
    );
};

export default Toolbar;