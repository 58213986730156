import React from 'react';

import jsIcon from '../../assets/images/tech-icons/javascript.svg';
import htmlIcon from '../../assets/images/tech-icons/html.svg';
import cssIcon from '../../assets/images/tech-icons/css.svg';
import jqueryIcon from '../../assets/images/tech-icons/jquery.svg';
import gitIcon from '../../assets/images/tech-icons/git.png';
import expressIcon from '../../assets/images/tech-icons/expressjs.svg';
import nodeJsIcon from '../../assets/images/tech-icons/nodejs.svg';
import mongoDbIcon from '../../assets/images/tech-icons/mongodb.svg';
import angularJsIcon from '../../assets/images/tech-icons/angular.svg';
import gitHubIcon from '../../assets/images/tech-icons/github.svg';
import rubyIcon from '../../assets/images/tech-icons/ruby.svg';
import rubyOnRailsIcon from '../../assets/images/tech-icons/rubyonrails.svg';
import postgreSqlIcon from '../../assets/images/tech-icons/postgresql.svg';

import project1Thumbnail from '../../assets/images/project-images/project-1-thumbnail.png';
import project2Thumbnail from '../../assets/images/project-images/project-2-thumbnail.png';
import project3Thumbnail from '../../assets/images/project-images/project-3-thumbnail.png';
import project4Thumbnail from '../../assets/images/project-images/project-4-thumbnail.png';

const javaScript = { "text": "JavaScript", "icon": jsIcon, "img-alt": "JavaScript logo" };
const html = { "text": "HTML", "icon": htmlIcon, "img-alt": "HTML icon" };
const css = { "text": "CSS/SCSS", "icon": cssIcon, "img-alt": "CSS icon" };
const jQuery = { "text": "jQuery", "icon": jqueryIcon, "img-alt": "jQuery logo" };
const git = { "text": "git", "icon": gitIcon, "img-alt": "git logo" };
const express = { "text": "Express.js", "icon": expressIcon, "img-alt": "Express.js icon" };
const ejs = { "text": "EJS", "icon": "", "img-alt": "EJS" };
const nodeJS = { "text": "Node.js", "icon": nodeJsIcon, "img-alt": "Node.js logo" };
const mongoDB = { "text": "MongoDB", "icon": mongoDbIcon, "img-alt": "MongoDB logo" };
const mongoose = { "text": "Mongoose", "icon": "", "img-alt": "Mongoose" };
const bcrypt = { "text": "BCrypt", "icon": "", "img-alt": "BCrypt" };
const angularJS = { "text": "AngularJS", "icon": angularJsIcon, "img-alt": "AngularJS" };
const gitHubTeam = { "text": "GitHub (Team collaboration)", "icon": gitHubIcon, "img-alt": "GitHub" };
const ruby = { "text": "Ruby", "icon": rubyIcon, "img-alt": "Ruby" };
const rubyOnRails = { "text": "Ruby on Rails", "icon": rubyOnRailsIcon, "img-alt": "Ruby on Rails" };
const postgreSQL = { "text": "PostgreSQL", "icon": postgreSqlIcon, "img-alt": "PostgreSQL" };


let project1Tech = [{...javaScript}, {...html}, {...css}, {...jQuery}, {...git}];
setTagSize(project1Tech, 'small');
setTagColour(project1Tech, '#ddd');

const project1Description = (
    <React.Fragment>
        <p>
            Battleship is a popular turn based game where the aim of the game is to sink your
            opponent's ships before they sink yours. In this version of Battleship, you'll play against
            the computer and your ships will be randomly placed for you at the start of the game.
        </p>
        <p>
            The game's code is split into four fundamental sections which are creating the grids, 
            placing the ships on the grids, the battle system and the AI.
        </p>
    </React.Fragment>
);

const project1 = {
    "title": "Battleship",
    "subtitle": "Game in the browser",
    "image": {
        "thumbnail": project1Thumbnail,
        "altText": "Battleship thumbnail"
    },
    "description": project1Description,
    "techUsed": project1Tech,
    "links": [
        {
            "name": "Details",
            "url": "https://github.com/laseq/wdi-project-1",
            "helpText": "View project details on GitHub",
            "faIcon": "book-open"
        },
        {
            "name": "Live site",
            "url": "https://protected-waters-78340.herokuapp.com",
            "helpText": "View the live project",
            "faIcon": "globe"
        }
    ]
}


let project2Tech = [{...express}, {...ejs}, {...nodeJS}, {...mongoDB}, {...mongoose}, {...bcrypt},
    {...html}, {...css}, {...javaScript}, {...jQuery}, {...git}];
setTagSize(project2Tech, 'small');
setTagColour(project2Tech, '#ddd');

const project2Description = (
    <React.Fragment>
        <p>
            The requirement for our second project was to create a Full Stack RESTful application with authentication
            with the use of a non relational database and Embedded JavaScript templating to render web pages.
        </p>
        <p>
            My project of choice was a web app to enable learners of Japanese to improve their Kanji (Japanese symbol) competence. 
            As a learner of the Japanese language I felt that it would be an exciting project to embark on.
        </p>
        <p>
            Such an app would fulfil the project requirements with RESTful routes and include some logic for the lessons.
        </p>
    </React.Fragment>
);

const project2 = {
    "title": "Kanji Kaigou",
    "subtitle": "Full Stack RESTful app with authentication",
    "image": {
        "thumbnail": project2Thumbnail,
        "altText": "Kanji Kaigou thumbnail"
    },
    "description": project2Description,
    "techUsed": project2Tech,
    "links": [
        {
            "name": "Details",
            "url": "https://github.com/laseq/wdi-project-2",
            "helpText": "View project details on GitHub",
            "faIcon": "book-open"
        },
        {
            "name": "Live site",
            "url": "https://whispering-harbor-84338.herokuapp.com",
            "helpText": "View the live project",
            "faIcon": "globe"
        }
    ]
}

let project3Tech = [ {...angularJS}, {...nodeJS}, {...express}, {...mongoDB}, {...mongoose}, {...bcrypt},
    {...html}, {...css}, {...javaScript}, {...git}, {...gitHubTeam}];
setTagSize(project3Tech, 'small');
setTagColour(project3Tech, '#ddd');

const project3Description = (
    <React.Fragment>
        <p>
            The requirement for our team project was to create a MEAN stack (MongoDB, Express.js, AngularJS and Node.js) application 
            with an API and authentication.
        </p>
        <p>
            RUNCH is an app targeted at getting people who have little spare time, active. 
            It enables them to use their lunch-time wisely - they can go for a quick run and meet other like-minded 
            professionals. It is an opportunity to not only get fit, but also to network, and feel refreshed for 
            the second half of the day.
        </p>
    </React.Fragment>
);

const project3 = {
    "title": "RUNCH",
    "subtitle": "MEAN stack app built as a team",
    "image": {
        "thumbnail": project3Thumbnail,
        "altText": "RUNCH thumbnail"
    },
    "description": project3Description,
    "techUsed": project3Tech,
    "links": [
        {
            "name": "Details",
            "url": "https://github.com/laseq/wdi-group-project",
            "helpText": "View project details on GitHub",
            "faIcon": "book-open"
        },
        {
            "name": "Live site",
            "url": "https://runchtime.herokuapp.com",
            "helpText": "View the live project",
            "faIcon": "globe"
        }
    ]
}


let project4Tech = [ {...ruby}, {...rubyOnRails}, {...postgreSQL}, {...angularJS}, {...bcrypt},
    {...html}, {...css}, {...javaScript}, {...git}];
setTagSize(project4Tech, 'small');
setTagColour(project4Tech, '#ddd');

const project4Description = (
    <React.Fragment>
        <p>
            We were required to craft a Full Stack Rails application with an SQL database, 
            authentication and an AngularJS front-end that consumes API routes 
            created on the server-side.
        </p>
        <p>
            Henstagenda enables you to organise stag and hen dos by creating groups, 
            inviting friends and organising an agenda of events for the party. 
            Group members can keep up to date with events and provide their attendance status.
        </p>
        <p>
            The project improved my experience with Ruby on Rails through creating many 
            custom routes and methods that interacted with the database to pass relevant data 
            on to the client side.
        </p>
    </React.Fragment>
);

const project4 = {
    "title": "Henstagenda",
    "subtitle": "Ruby on Rails app with an SQL database",
    "image": {
        "thumbnail": project4Thumbnail,
        "altText": "Henstagenda thumbnail"
    },
    "description": project4Description,
    "techUsed": project4Tech,
    "links": [
        {
            "name": "Details",
            "url": "https://github.com/laseq/wdi-project-4-client",
            "helpText": "View project details on GitHub",
            "faIcon": "book-open"
        },
        {
            "name": "Live site",
            "url": "https://agile-cliffs-75809.herokuapp.com/",
            "helpText": "View the live project",
            "faIcon": "globe"
        }
    ]
}

const GAProjects = [project1, project2, project3, project4];

export default GAProjects;


// helper methods below

function setTagSize(tagsArray, size) {
    tagsArray.forEach(tag => {
        tag.tagSize = size;
    });
}

function setTagColour(tagsArray, hexCode) {
    tagsArray.forEach(tag => {
        tag.tagColour = hexCode;
    });
}