import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './SimpleAccordion.module.scss';

const SimpleAccordion = (props) => {

    const [iconAnimation, setIconAnimation] = useState(null);
    const [contentAnimation, setContentAnimation] = useState(null);
    const [accordionStatus, setAccordionStatus] = useState('closed');

    const headingClickedHandler = () => {
        if (accordionStatus === 'closed' || accordionStatus === 'closing') {
            setAccordionStatus('opening');
            setIconAnimation(classes.IconOpening);
            setContentAnimation(classes.ContentOpening);
        } else if (accordionStatus === 'open' || accordionStatus === 'opening') {
            setAccordionStatus('closing');
            setIconAnimation(classes.IconClosing);
            setContentAnimation(classes.ContentClosing);
        }
    };

    const cleanUpAnimations = () => {
        if (!iconAnimation && !contentAnimation)
            return;

        setTimeout(function() {
            setIconAnimation(null);
        }, 100);

        setTimeout(function() {
            setContentAnimation(null);

            if (accordionStatus === 'closing')
                setAccordionStatus('closed');
            else if (accordionStatus === 'opening') {
                setAccordionStatus('open');
            }

        }, 100);
    };
    useEffect(cleanUpAnimations, [iconAnimation, contentAnimation]);

    const contentClasses = [classes.Content];
    const expandButtonClasses = [classes.ExpandButton];

    let icon;
    if (accordionStatus === 'open') {
        icon = <FontAwesomeIcon icon="chevron-up"/>;
        contentClasses.push(classes.Open);
    } else if (accordionStatus === 'opening') {
        icon = <FontAwesomeIcon icon="chevron-up"/>;
    } else if (accordionStatus === 'closed') {
        icon = <FontAwesomeIcon icon="chevron-down"/>;
        contentClasses.push(classes.Closed);
    } else if (accordionStatus === 'closing') {
        icon = <FontAwesomeIcon icon="chevron-down"/>;
    }

    if (contentAnimation)
        contentClasses.push(contentAnimation);

    if (iconAnimation)
        expandButtonClasses.push(iconAnimation);

    return (
        <div className={classes.Accordion}>
            <div className={classes.HeadingRow} onClick={headingClickedHandler}>
                <span className={expandButtonClasses.join(' ')}>
                    {icon}
                </span>
                <span className={classes.Heading}>
                    {props.heading}
                </span>
            </div>
            <div className={contentClasses.join(' ')}>
                {props.children}
            </div>
        </div>
    );
};

export default SimpleAccordion;