import React, { useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import classes from './Skills.module.scss';
import Banner from '../../components/Banners/Banner/Banner';
import PageContent from '../Layout/PageContent/PageContent';
import Section from '../Layout/ContentSection/ContentSection';
import InfoTags from '../../components/Tags/InfoTags/InfoTags';
import * as tagsConfig from './SkillsTags.config';

const Skills = (props) => {

    useEffect(props.setToolbarTheme, []);

    const skillsCollection = [
        { heading: 'Languages', tags: tagsConfig.languages },
        { heading: 'Front-end Development', tags: tagsConfig.frontEnd },
        { heading: 'Back-end Development', tags: tagsConfig.backEnd },
        { heading: 'Databases', tags: tagsConfig.databases },
        { heading: 'Cloud Computing Platforms', tags: tagsConfig.cloudComputingPlatforms },
        { heading: 'Content Management Systems', tags: tagsConfig.CMSes },
        { heading: 'Testing', tags: tagsConfig.testing }
    ];

    const skillsDisplay = skillsCollection.map((category, index) => {
        return (
            <ScrollAnimation key={index} animateIn={classes.SlideIn} animateOnce>
                <Section>
                    <div className={classes.SkillsWrapper}>
                        <h2>{category.heading}</h2>
                        <InfoTags tags={category.tags} />
                    </div>
                </Section>
            </ScrollAnimation>
        );
    });

    return (
        <React.Fragment>
            <Banner theme="book" titleShadow={true}>Skills</Banner>
            <PageContent>
                {skillsDisplay}
            </PageContent>
        </React.Fragment>
    );
};

export default Skills;