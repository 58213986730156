import React from 'react';

import classes from './InfoTag.module.scss';

const InfoTag = (props) => {

    let outerClasses = [classes.Tag];

    let icon;
    if (props.icon && props.icon !== "" && props.icon !== 'default') {
        icon = (
            <span className={classes.Icon}>
                <img src={props.icon} alt={props['img-alt']}/>
            </span>
        );
    }

    if (props.tagSize && props.tagSize === 'small') {
        outerClasses.push(classes.Small);
    }

    return (
        <li className={outerClasses.join(' ')}>
            {icon}
            <span className={classes.Text}>{props.text}</span>
        </li>
    );
};

export default InfoTag;