import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './Circle.module.scss';

const CircleButton = (props) => {

    let buttonClasses = [];

    switch(props.colour) {
        case 'green':
            break;
        case 'blue':
            buttonClasses.push(classes.Blue);
            break;
        default:
            break;
    }

    if (props.active)
        buttonClasses.push(classes.Active);

    let faIcon;
    if (props.faIcon) {
        buttonClasses.push(classes.FaIcon);

        faIcon = (
            <div
                className={buttonClasses.join(' ')}
                title={props.helpText}
                onClick={props.clicked}>
                <FontAwesomeIcon icon={props.faIcon}/>
            </div>
        );
    }

    return (faIcon);
};

export default CircleButton;