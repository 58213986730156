import React, { useEffect, useRef } from 'react';

import classes from './CherryBlossoms.module.scss';
import Petal from './models/Petal.model';
import petalImgs from './images/petalImages';

const CherryBlossomsCanvas = (props) => {

    const animationCanvasRef = useRef(null);
    
    useEffect(() => {
        // initialise the animation canvas
        const animationCanvas = new AnimationCanvas(animationCanvasRef);
        animationCanvas.initCanvas();

        // clear the canvas on component unmounting
        return animationCanvas.clearCanvas;
    }, []);

    return (
        <canvas ref={animationCanvasRef} className={classes.Canvas}></canvas>
    );
};

export default CherryBlossomsCanvas;

class AnimationCanvas {
    constructor(animationCanvasRef) {
        this.canvas = animationCanvasRef.current;
        this.canvas.width = animationCanvasRef.current.offsetWidth;
        this.canvas.height = animationCanvasRef.current.offsetHeight;
        
        this.ctx = animationCanvasRef.current.getContext("2d");

        this.petalImages = [];
        this.numOfEachPetalImg = 0;
        this.petals = [];
    }

    initCanvas = () => {
        window.addEventListener("resize", this.handleWindowResize);

        this.petalImages = this.getPetalImages();
        this.numOfEachPetalImg = this.getNumOfEachPetalImg();
        this.createPetals();

        this.animate();
    };

    handleWindowResize = () => {
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
    };

    clearCanvas = () => {
        // canvas will be cleared when this component unmounts
        this.animate = () => null;
        window.removeEventListener("resize", this.handleWindowResize);
    };

    animate = () => {
        requestAnimationFrame(() => this.animate());

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.petals.forEach(petal => {
            petal.update();
        });
    };

    getNumOfEachPetalImg = () => {
        const width = this.canvas.width;
        let num = 1;
        
        if (width >=1600)
            num = 5;
        else if (width >= 1200)
            num = 4;
        else if (width >= 800)
            num = 3;
        else if (width >= 600)
            num = 2;
        else
            num = 1;

        return num;
    };

    getPetalImages = () => {
        let petalImg1 = new Image(); petalImg1.src = petalImgs.petal1;
        let petalImg2 = new Image(); petalImg2.src = petalImgs.petal2;
        let petalImg3 = new Image(); petalImg3.src = petalImgs.petal3;
        let petalImg4 = new Image(); petalImg4.src = petalImgs.petal4;
        let petalImg5 = new Image(); petalImg5.src = petalImgs.petal5;
        let petalImg6 = new Image(); petalImg6.src = petalImgs.petal6;

        let petalImg1Flipped = new Image(); petalImg1Flipped.src = petalImgs.petal1Flipped;
        let petalImg2Flipped = new Image(); petalImg2Flipped.src = petalImgs.petal2Flipped;
        let petalImg3Flipped = new Image(); petalImg3Flipped.src = petalImgs.petal3Flipped;
        let petalImg4Flipped = new Image(); petalImg4Flipped.src = petalImgs.petal4Flipped;
        let petalImg5Flipped = new Image(); petalImg5Flipped.src = petalImgs.petal5Flipped;
        let petalImg6Flipped = new Image(); petalImg6Flipped.src = petalImgs.petal6Flipped;
        
        let petalImages = [petalImg1, petalImg2, petalImg3, petalImg4, petalImg5, petalImg6,
            petalImg1Flipped, petalImg2Flipped, petalImg3Flipped, petalImg4Flipped, petalImg5Flipped, petalImg6Flipped];

        return petalImages;
    };

    createPetals = () => {
        const canvasProps = {
            canvas: this.canvas,
            ctx: this.ctx
        };

        this.petalImages.forEach(petalImage => {

            // need to check that the petalImage actually exists
            // before pushing many petals of this image into this.petals
            petalImage.onload = () => {
                generatePetalObjects(petalImage);
            };
        });

        const generatePetalObjects = (petalImage) => {
            const numPetals = this.numOfEachPetalImg;

            for (let i = 0; i < numPetals; i++) {
                const width = Math.floor((Math.random() * 40) + 10);
                const height = width / 1.25;
                const x = Math.floor(Math.random() * (this.canvas.width - width));
                const y = Math.floor(Math.random() * this.canvas.height);

                const physics = {
                    sway: Math.floor(((Math.random() * (width/4)) + 5) + width/4),
                    dy: (Math.random() * 0.75) + 0.25,
                    rotation: Math.floor((Math.random() * 20) + 5)

                };
                physics.swayPeriod = Math.floor((physics.sway * 10) + ((1/width)*100));
                
                this.petals.push(new Petal(canvasProps, petalImage, x, y, width, height, physics));
            }
        }
    };

}