import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavItem.module.scss';

const NavItem = (props) => {
    
    let navItemClasses = [classes.NavItem];

    if (!props.scrollMilestone || props.scrollMilestone < 40) {
        switch(props.toolbarTheme) {
            case 'homepage': navItemClasses.push(classes.CustomTheme, classes.HomePageTheme); break;
            case 'skills': navItemClasses.push(classes.CustomTheme, classes.SkillsPageTheme); break;
            case 'journey': navItemClasses.push(classes.CustomTheme, classes.JourneyPageTheme); break;
            case 'projects': navItemClasses.push(classes.CustomTheme, classes.ProjectsPageTheme); break;
            default: break;
        }
    }

    switch(props.scrollMilestone) {
        case 40: navItemClasses.push(classes.Transition40); break;
        case 50: navItemClasses.push(classes.Transition50); break;
        case 60: navItemClasses.push(classes.Transition60); break;
        case 70: navItemClasses.push(classes.Transition70); break;
        case 80: navItemClasses.push(classes.Transition80); break;
        case 90: navItemClasses.push(classes.Transition90); break;
        case 100: navItemClasses = [classes.NavItem]; break;
        default: break;
    }

    return (
        <li className={navItemClasses.join(' ')} onClick={props.pageChanged}>
            <NavLink
                to={props.link}
                exact={props.exact}
                activeClassName={classes.active}>{props.children}</NavLink>
        </li>
    );
};

export default NavItem;