import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './Footer.module.scss';

const Footer = (props) => {

    const githubIcon = (
        <a href="https://github.com/laseq"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.Icon}>
            <FontAwesomeIcon icon={['fab', 'github-square']} />
        </a>
    );
    const linkedinIcon = (
        <a href="https://www.linkedin.com/in/lourencosequeira/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.Icon}>
            <FontAwesomeIcon icon={['fab', 'linkedin']} />
        </a>
    );

    return (
        <footer className={classes.Footer}>
            <section className={classes.Content}>
                <div className={[classes.Links, classes.MobileOnly].join(' ')}>
                    {githubIcon}
                    {linkedinIcon}
                </div>
                <div className={classes.Text}>
                    <p>Copyright &copy; 2021 Lourenco Sequeira</p>
                </div>
                <div className={[classes.Links, classes.DesktopOnly].join(' ')}>
                    {githubIcon}
                    {linkedinIcon}
                </div>
            </section>
        </footer>
    );
};

export default Footer;