import icons from '../../libraries/techIcons';

export const languages = [
    {
        "text": "JavaScript",
        "icon": icons.javaScript,
        "img-alt": "JavaScript logo"
    },
    {
        "text": "C#",
        "icon": icons.cSharp,
        "img-alt": "C# logo"
    },
    {
        "text": "Ruby",
        "icon": icons.ruby,
        "img-alt": "Ruby logo"
    },
    {
        "text": "SQL",
        "icon": icons.sql,
        "img-alt": "Database icon for SQL tag"
    }
];

export const frontEnd = [
    {
        "text": "React",
        "icon": icons.react,
        "img-alt": "React logo"
    },
    {
        "text": "AngularJS",
        "icon": icons.angular,
        "img-alt": "Angular JS logo"
    },
    {
        "text": "jQuery",
        "icon": icons.jQuery,
        "img-alt": "jQuery logo"
    },
    {
        "text": "TypeScript",
        "icon": icons.typeScript,
        "img-alt": "TypeScript logo"
    },
    {
        "text": "HTML",
        "icon": icons.html,
        "img-alt": "HTML logo"
    },
    {
        "text": "CSS/SCSS",
        "icon": icons.css,
        "img-alt": "CSS logo"
    },
    {
        "text": "Bootstrap",
        "icon": icons.bootstrap,
        "img-alt": "Bootstrap logo"
    },
    {
        "text": "Materialize",
        "icon": icons.materialize,
        "img-alt": "Materialize"
    },
    {
        "text": "Fluent UI",
        "icon": icons.fluentUI,
        "img-alt": "Fluent UI"
    }
];

export const backEnd = [
    {
        "text": "Node.js",
        "icon": icons.nodeJs,
        "img-alt": "Node.js logo"
    },
    {
        "text": "Express.js",
        "icon": icons.expressJs,
        "img-alt": "Express.js logo"
    },
    {
        "text": "ASP.NET",
        "icon": icons.dotNet,
        "img-alt": ".NET logo"
    },
    {
        "text": "ASP.NET Core",
        "icon": icons.dotNetCore,
        "img-alt": ".NET Core logo"
    },
    {
        "text": "Ruby on Rails",
        "icon": icons.rubyOnRails,
        "img-alt": "Ruby on Rails logo"
    }
];

export const databases = [
    {
        "text": "MongoDB",
        "icon": icons.mongoDB,
        "img-alt": "MongoDB logo"
    },
    {
        "text": "SQL Server",
        "icon": icons.sqlServer,
        "img-alt": "SQL Server logo"
    },
    {
        "text": "PostgreSQL",
        "icon": icons.postgreSQL,
        "img-alt": "PostgreSQL logo"
    }
];

export const cloudComputingPlatforms = [
    {
        "text": "Microsoft Azure",
        "icon": icons.azure,
        "img-alt": "Azure logo"
    }
];

export const CMSes = [
    {
        "text": "Umbraco",
        "icon": icons.umbraco,
        "img-alt": "Umbraco logo"
    },
    {
        "text": "Contensis",
        "icon": icons.contensis,
        "img-alt": "Contensis logo"
    }
];

export const testing = [
    {
        "text": "Mocha",
        "icon": icons.mocha,
        "img-alt": "Mocha logo"
    },
    {
        "text": "Chai",
        "icon": icons.chai,
        "img-alt": "Chai logo"
    },
    {
        "text": "Selenium",
        "icon": icons.selenium,
        "img-alt": "Selenium logo"
    },
    {
        "text": "NUnit",
        "icon": icons.nUnit,
        "img-alt": "NUnit logo"
    }
];