import React, { useRef, useEffect, useState } from 'react';

import classes from './CanvasStyles.module.scss';
import Meteor from '../Models/Meteor.model';
import MeteorPreRenderCanvas from './MeteorPreRenderCanvas';

const MeteorsCanvas = (props) => {

    const mainCanvasRef = useRef(null);
    const preRenderedCanvasRefs = useRef({});

    const [mainCanvas, setMainCanvas] = useState(null);
    const [meteorPreRenderValues, setMeteorPreRenderValues] = useState([]);
    const [meteorPreRenderCanvases, setMeteorPreRenderCanvases] = useState([]);

    useEffect(initialiseCanvas, []);
    useEffect(createPreRenderCanvases, [meteorPreRenderValues]);
    useEffect(linkPreRenderCanvases);

    function initialiseCanvas() {
        const newMainCanvas = new Canvas(mainCanvasRef, preRenderedCanvasRefs, setMeteorPreRenderValues);
        setMainCanvas(newMainCanvas);

        // clear the canvas on component unmounting
        return newMainCanvas.clearCanvas;
    }

    function createPreRenderCanvases () {
        if (meteorPreRenderValues.length < 1)
            return;

        // create the pre-rendered canvases
        // these canvases will be created in the DOM
        preRenderedCanvasRefs.current = {};

        const preRenderCanvases = [];
        for (let key in meteorPreRenderValues) {
            const meteorVals = meteorPreRenderValues[key];

            preRenderCanvases.push(
                <MeteorPreRenderCanvas
                        key={key}
                        identifier={meteorVals.identifier}
                        preRenderedCanvasRefs={preRenderedCanvasRefs}
                        width={meteorVals.width}
                        height={meteorVals.height}
                        dimensions={meteorVals.dimensions}
                        colours={meteorVals.colours}
                        velocity={meteorVals.velocity}
                        timings={meteorVals.timings}/>
            );
        }

        if (preRenderCanvases.length) {
            setMeteorPreRenderCanvases(preRenderCanvases);
        }
    }

    function linkPreRenderCanvases() {
        // link the ascending stars to their relevant cached pre-rendered canvases
        if (Object.keys(preRenderedCanvasRefs.current).length > 0) {
            mainCanvas.linkMeteorsToPreCanvases();
        }
    }

    return (
        <div className="MeteorsCanvasWrapper">
            <canvas
                ref={mainCanvasRef}
                className={[classes.Canvas, classes.AnimationCanvas].join(' ')}>
            </canvas>
            <div className={classes.PreRenderedCanvases}>
                {meteorPreRenderCanvases}
            </div>
        </div>
    );
};

export default MeteorsCanvas;


class Canvas {
    constructor(canvasRef, preRenderedCanvasRefs, setMeteorPreRenderValues) {
        this.canvas = canvasRef.current;
        this.canvas.width = canvasRef.current.offsetWidth;
        this.canvas.height = canvasRef.current.offsetHeight;
        this.ctx = canvasRef.current.getContext("2d");

        this.preRenderedCanvasRefs = preRenderedCanvasRefs;
        this.setMeteorPreRenderValues = setMeteorPreRenderValues;
        this.preRenderValues = {};

        this.numMeteors = 0;
        this.meteors = [];
        this.meteorMinAngle = 10; // degrees
        this.headRadiusPossibilities = [];
        this.minVelocity = 20;

        this.previousAnimationTime = Date.now();

        this.initCanvas();
    }

    initCanvas = () => {
        window.addEventListener("resize", this.handleWindowResize);
    
        this.setScreenWidthSpecificVariables();
    
        // initiate the objects here
        this.createMeteors();
    
        // call the animate function here
        this.animate();
    };

    handleWindowResize = () => {
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
        // resize meteors?
        this.setMeteorPropertiesForScreenWidth();
        // this.updateMeteorProperties();
    };

    // updateMeteorProperties = (() => {
    //     let updateInProgress = false;

    //     function update() {
    //         if (!updateInProgress) {

    //             updateInProgress = true;

    //             // this.meteors.forEach(meteor => {
    //             //     const newAngle = Math.floor((Math.random() * 5) + this.meteorMinAngle);
    //             //     meteor.direction = (newAngle/180) * Math.PI;
    //             //     meteor.dx = meteor.velocity * Math.cos(meteor.direction);
    //             //     meteor.dy = meteor.velocity * Math.sin(meteor.direction);
    //             // });
                
    //             updateInProgress = false;
    //         }
    //     }
        
    //     return update;
    // })();

    clearCanvas = () => {
        this.animate = () => null;
        window.removeEventListener("resize", this.handleWindowResize);
    }

    createMeteors = () => {
        for (let i=0; i < this.numMeteors; i++) {
            const mainCanvasProps = {
                canvas: this.canvas,
                context: this.ctx
            };
            // const headRadius = (Math.random() * 2.4) + 0.1;
            const headRadius = this.headRadiusPossibilities[Math.floor(Math.random() * this.headRadiusPossibilities.length)];
            const trailLength = (Math.floor(Math.random() * 200)) + 800 + (headRadius * 100);
            const angle = Math.floor((Math.random() * 5) + this.meteorMinAngle);
            const dimensions = {
                headRadius: headRadius,
                trailLength: trailLength,
                angle: angle,
                blurRadius: headRadius
            };
            dimensions.direction = (dimensions.angle/180) * Math.PI;

            const velocity = Math.floor((Math.random() * 10) + this.minVelocity);
            const timings = {
                firstSpawnDelay: Math.floor(Math.random() * 10),
                respawnInterval: Math.floor((Math.random() * 15) + 5 + (headRadius * 3))
            };
            const colours = {
                glowColour: '#bfedff',
                headColour: '#fff',
                trailColour: '#b5ebff'
            };
    
            let preValues = null;
            const preCanvasIdentifier = `rad${headRadius}-trail${trailLength}`;
            if (!this.preRenderValues[preCanvasIdentifier]) {

                preValues = {
                    identifier: preCanvasIdentifier,
                    width: Math.ceil((headRadius + trailLength) * Math.cos(dimensions.direction)) + 20,
                    height: Math.ceil((headRadius + trailLength) * Math.sin(dimensions.direction)) + 20,
                    dimensions: dimensions,
                    colours: colours,
                    velocity: velocity,
                    timings: timings
                };

                this.preRenderValues[preCanvasIdentifier] = preValues;
            }

            this.meteors.push(new Meteor(mainCanvasProps, dimensions, velocity, timings, preCanvasIdentifier));
        }

        // put everything in the meteorPreRenderCanvases array
        // these will eventually get created as canvas elements in the DOM
        this.setMeteorPreRenderValues(this.preRenderValues);
    };

    linkMeteorsToPreCanvases = () => {
        this.meteors.forEach(meteor => {
            const matchingPreCanvas = this.preRenderValues[meteor.preRenderedCanvasIdentifier];

            if (matchingPreCanvas) {
                meteor.preRenderedCanvas = this.preRenderedCanvasRefs.current[matchingPreCanvas.identifier];
            }
        });
    };

    animate = () => {
        requestAnimationFrame(this.animate);
    
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    
        const currentAnimationTime = Date.now();
        if (currentAnimationTime > this.previousAnimationTime + 3000) {
            // make sure meteors don't fire at the same time when
            // leaving canvas and coming back to it
            this.staggerMeteorAnimations();
        }

        // call the update function for each object here
        this.meteors.forEach(meteor => {
            meteor.update();
        });

        this.previousAnimationTime = Date.now();
    };

    staggerMeteorAnimations = () => {
        const now = Date.now();
        const meteorsThatWillFire = this.meteors.filter(meteor => {
            return !meteor.isVisible && meteor.nextSpawnTime <= now;
        });

        if (meteorsThatWillFire.length > 1) {
            let date = new Date();
            for (let i = 0; i < meteorsThatWillFire.length; i++) {
                meteorsThatWillFire[i].nextSpawnTime = date.setSeconds(date.getSeconds() + (0.5 * i));
            }
        }
    };

    setScreenWidthSpecificVariables = () => {    
        if (this.canvas.width >=1600) {
            this.numMeteors = 9;
        } else if (this.canvas.width >= 1200) {
            this.numMeteors = 8;
        } else if (this.canvas.width >= 800) {
            this.numMeteors = 7;
        } else if (this.canvas.width >= 600) {
            this.numMeteors = 7;
        } else {
            this.numMeteors = 6;
        }

        this.setMeteorPropertiesForScreenWidth();
    };

    setMeteorPropertiesForScreenWidth = () => {
        if (this.canvas.width >=1600) {
            this.meteorMinAngle = 10;
            this.headRadiusPossibilities = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
            this.minVelocity = 20;
        } else if (this.canvas.width >= 1200) {
            this.meteorMinAngle = 15;
            this.headRadiusPossibilities = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75];
            this.minVelocity = 17;
        } else if (this.canvas.width >= 800) {
            this.meteorMinAngle = 20;
            this.headRadiusPossibilities = [0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4];
            this.minVelocity = 13;
        } else if (this.canvas.width >= 600) {
            this.meteorMinAngle = 25;
            this.headRadiusPossibilities = [0.2, 0.4, 0.6, 0.8, 1, 1.2];
            this.minVelocity = 11;
        } else {
            this.meteorMinAngle = 30;
            this.headRadiusPossibilities = [0.2, 0.4, 0.6, 0.8, 1];
            this.minVelocity = 8;
        }
    };
};