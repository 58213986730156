class Meteor {
    constructor(canvasProps, dimensions, velocity=2, timings, preRenderedCanvasIdentifier) {
        this.canvas = canvasProps.canvas;
        this.ctx = canvasProps.context;

        this.headRadius = dimensions.headRadius;
        this.trailLength = dimensions.trailLength;
        this.direction = dimensions.direction;
        this.velocity = velocity;
        this.dx = velocity * Math.cos(this.direction);
        this.dy = velocity * Math.sin(this.direction);
        this.trail = {
            xLength: this.trailLength * Math.cos(this.direction),
            yLength: this.trailLength * Math.sin(this.direction)
        }

        this.visible = false;

        let firstSpawnTime = new Date();
        firstSpawnTime.setSeconds(firstSpawnTime.getSeconds() + timings.firstSpawnDelay);
        this.nextSpawnTime = firstSpawnTime;
        this.respawnInterval = timings.respawnInterval;

        this.preRenderedCanvasIdentifier = preRenderedCanvasIdentifier;
        this.preRenderedCanvas = null;
    }

    drawPreRenderedMeteor() {
        this.ctx.drawImage(
            this.preRenderedCanvas,
            this.x - this.preRenderedCanvas.width,
            this.y - this.preRenderedCanvas.height
        );
    }

    move() {
        if ((this.x - this.trail.xLength <= this.canvas.width) && (this.y - this.trail.yLength <= this.canvas.height)) {
            this.x += this.dx;
            this.y += this.dy;
        } else {
            this.visible = false;
            let newSpawnTime = new Date();
            newSpawnTime.setSeconds(newSpawnTime.getSeconds() + this.respawnInterval);
            this.nextSpawnTime = newSpawnTime;
        }
    }

    respawn() {
        const respawnEdge = Math.ceil(Math.random() * 2);
        if (respawnEdge === 1) {
            // respawn meteor from the top
            this.x = Math.random() * (this.canvas.width/2);
            this.y = -this.headRadius;
        } else {
            // respawn meteor from the left side
            this.x = 0;
            this.y = Math.random() * (this.canvas.height/2);
        }
    }

    update() {
        if (!this.visible) {
            if (this.nextSpawnTime <= Date.now()) {
                this.visible = true;
            }
            
            if (this.visible) {
                this.respawn();
            }
        }

        if (this.visible) {
            this.move();
            
            if (this.preRenderedCanvas) {
                this.drawPreRenderedMeteor();
            }
        }
    };
}

export default Meteor;