import React from 'react';
import { NavLink } from 'react-router-dom';

import { techTags as tags } from '../../libraries/tags';

// start of General Assembly data
const generalAssemblySummary = (
    <React.Fragment>
        <p>I attended the Web Development Immersive course at General Assembly, London, and had one of the best learning experiences of my life.</p>
        <p>It was intense and challenging from the get go and has given me a great understanding of good web development practices that has shaped me into the developer that I am today.</p>
        <p>The positive experience has enabled me to create a Web Development portfolio through course projects which can be viewed <NavLink to="/projects">here</NavLink>.</p>
    </React.Fragment>
);
const generalAssemblyTech = [
    tags.javaScript,
    tags.html,
    tags.css,
    tags.jQuery,
    tags.expressJS,
    tags.ejs,
    tags.nodeJS,
    tags.mongoDB,
    tags.angularJS,
    tags.ruby,
    tags.rubyOnRails,
    tags.postgreSQL,
    tags.git,
    tags.bootstrap,
    tags.materialize,
    tags.mocha,
    tags.chai
];
// setTagSize(generalAssemblyTech, 'small');
// setTagColour(generalAssemblyTech, '#ddd');

const generalAssembly = {
    title: 'General Assembly',
    subtitle: 'Web Development Immersive course',
    timePeriod: 'Mar to Jun 2017',
    summary: generalAssemblySummary,
    techUsed: generalAssemblyTech
};
// end of General Assembly data

// start of Royal Holloway data
const royalHollowaySummary = (
    <React.Fragment>
        <p>
            Being a Web Developer at Royal Holloway was my first professional development role and gave me a great
            insight into how a small team of a larger IT team integrates into a big organisation.
        </p>
        <p>
            My experience was intense from the beginning being tasked with developing a .NET Core application early on
            to enable students to sign in to their accommodation with a front end interface that would receive their
            signatures and store it in an encrypted format in a database. It was a challenging experience considering
            that I had not been acquainted with C# prior to this project and that the project had a short deadline.
        </p>
        <p>
            Majority of my work revolved around developing solutions to stakeholder needs in the ASP.NET based
            Content Management Systems Umbraco and Contensis. It was fulfulling designing and creating solutions
            from start to finish using various front and back end technologies with these CMSes and liaising
            with stakeholders to achieve the completion of tasks and projects.
        </p>
        <p>It was a great experience and a pleasure to be part of the Web Development team at Royal Holloway.</p>
    </React.Fragment>
);
const royalHollowayTech = [
    tags.cSharp,
    tags.javaScript,
    tags.angularJS,
    tags.jQuery,
    tags.html,
    tags.css,
    tags.aspDotNet,
    tags.sql,
    tags.sqlServer,
    tags.umbraco,
    tags.contensis,
    tags.azure
];
const royalHolloway = {
    title: 'Royal Holloway, University of London',
    subtitle: 'Web Developer',
    timePeriod: 'Aug 2017 to Oct 2019',
    summary: royalHollowaySummary,
    techUsed: royalHollowayTech
};
// end of Royal Holloway data

// start of Bestoutcome data
const bestoutcomeSummary = (
    <React.Fragment>
        <p>
            Bestoutcome specialises in Portfolio, Programme and Project Management and provides services and software
            products to empower clients to achieve their goals.
        </p>
        <p>
            Being part of the Software Development team I developed software functionality as requested by company stakeholders
            within an ASP.NET environment. I particularly enjoyed working with TypeScript on the front-end as it provides a logical
            strongly typed JavaScript environment.
        </p>
        <p>
            Working at Bestoutcome introduced me to various new software development practices and methodologies
            and has provided me with an appreciation for continuous improvement within large software systems.
        </p>
    </React.Fragment>
);

const bestoutcomeTech = [
    tags.cSharp,
    tags.aspDotNet,
    tags.sql,
    tags.sqlServer,
    tags.javaScript,
    tags.typeScript,
    tags.html,
    tags.css,
    tags.rdlcReports,
    tags.nUnit,
    tags.selenium
];

const bestoutcome = {
    title: 'Bestoutcome',
    subtitle: 'Software Developer',
    timePeriod: 'Oct 2019 to Oct 2020',
    summary: bestoutcomeSummary,
    techUsed: bestoutcomeTech
};
// end of Bestoutcome data

// start of WeVee data

const weveeSummary = (
    <React.Fragment>
        <p>
            WeVee is a credit brokerage start-up company that deals with electric car leases.
        </p>
        <p>
            As a member of the Technology Team, my key areas of responsbility are creating and improving 
            internal systems that include:
        </p>
        <ul>
            <li>A .NET Core Web API</li>
            <li>MS SQL and MS Azure SQL databases</li>
            <li>A React app to manage vehicle data, discounts and campaigns and to perform several other tasks</li>
            <li>Microsoft Powerapps PCF controls (coded in React) to enable the sales team to create vehicle lease quotes in the CRM</li>
            <li>The Azure cloud computing environment</li>
        </ul>
        <p>
            WeVee has provided me with the experience of working at a start-up company where 
            solutions need to be created from scratch, requirements change frequently and 
            implementations are expected to be delivered with quick turnarounds.
        </p>
    </React.Fragment>
);

const weveeTech = [
    tags.cSharp,
    tags.aspDotNetCore,
    tags.azure,
    tags.react,
    tags.javaScript,
    tags.typeScript,
    tags.sql,
    tags.sqlServer,
    tags.html,
    tags.css,
    tags.fluentUI,
    tags.nUnit
];

const wevee = {
    title: 'WeVee',
    subtitle: '.NET Developer',
    timePeriod: 'Nov 2020 to present',
    summary: weveeSummary,
    techUsed: weveeTech,
    lineStyle: 'dotted'
};

// end of WeVee data

const workExpList = [generalAssembly, royalHolloway, bestoutcome, wevee];

export default workExpList;