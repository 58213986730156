import icons from './techIcons';

export const techTags = {
    angularJS       : { "text": "AngularJS", "icon": icons.angular, "img-alt": "AngularJS" },
    aspDotNet       : { "text": "ASP.NET", "icon": icons.dotNet, "img-alt": ".NET icon" },
    aspDotNetCore   : { "text": "ASP.NET Core", "icon": icons.dotNetCore, "img-alt": ".NET Core icon" },
    azure           : { "text": "MS Azure", "icon": icons.azure, "img-alt": "Azure DevOps" },
    bcrypt          : { "text": "BCrypt", "icon": "", "img-alt": "BCrypt" },
    bootstrap       : { "text": "Bootstrap", "icon": icons.bootstrap, "img-alt": "Bootstrap icon" },
    chai            : { "text": "Chai", "icon": icons.chai, "img-alt": "Chai icon" },
    contensis       : { "text": "Contensis", "icon": icons.contensis, "img-alt": "Contensis icon" },
    cSharp          : { "text": "C#", "icon": icons.cSharp, "img-alt": "C# icon" },
    css             : { "text": "CSS/SCSS", "icon": icons.css, "img-alt": "CSS icon" },
    ejs             : { "text": "EJS", "icon": "", "img-alt": "EJS" },
    emailTemplates  : { "text": "Email templates", "icon": "", "img-alt": "Email templates" },
    expressJS       : { "text": "Express.js", "icon": icons.expressJs, "img-alt": "Express.js icon" },
    fluentUI        : { "text": "Fluent UI", "icon": icons.fluentUI, "img-alt": "Fluent UI icon" },
    git             : { "text": "git", "icon": icons.git, "img-alt": "git logo" },
    gitHubTeam      : { "text": "GitHub (Team collaboration)", "icon": icons.gitHub, "img-alt": "GitHub" },
    html            : { "text": "HTML", "icon": icons.html, "img-alt": "HTML icon" },
    javaScript      : { "text": "JavaScript", "icon": icons.javaScript, "img-alt": "JavaScript logo" },
    jQuery          : { "text": "jQuery", "icon": icons.jQuery, "img-alt": "jQuery logo" },
    materialize     : { "text": "Materialize", "icon": icons.materialize, "img-alt": "Materialize icon" },
    mocha           : { "text": "Mocha", "icon": icons.mocha, "img-alt": "Mocha icon" },
    mongoDB         : { "text": "MongoDB", "icon": icons.mongoDB, "img-alt": "MongoDB logo" },
    mongoose        : { "text": "Mongoose", "icon": "", "img-alt": "Mongoose" },
    nodeJS          : { "text": "Node.js", "icon": icons.nodeJs, "img-alt": "Node.js logo" },
    nUnit           : { "text": "nUnit", "icon": icons.nUnit, "img-alt": "nUnit icon" },
    postgreSQL      : { "text": "PostgreSQL", "icon": icons.postgreSQL, "img-alt": "PostgreSQL" },
    rdlcReports     : { "text": "RDLC reports", "icon": "", "img-alt": "RDLC icon" },
    react           : { "text": "React", "icon": icons.react, "img-alt": "React" },
    ruby            : { "text": "Ruby", "icon": icons.ruby, "img-alt": "Ruby" },
    rubyOnRails     : { "text": "Ruby on Rails", "icon": icons.rubyOnRails, "img-alt": "Ruby on Rails" },
    selenium        : { "text": "Selenium", "icon": icons.selenium, "img-alt": "Selenium icon" },
    sql             : { "text": "SQL", "icon": icons.sql, "img-alt": "SQL" },
    sqlServer       : { "text": "SQL Server", "icon": icons.sqlServer, "img-alt": "SQL Server" },
    typeScript      : { "text": "TypeScript", "icon": icons.typeScript, "img-alt": "TypeScript logo" },
    umbraco         : { "text": "Umbraco", "icon": icons.umbraco, "img-alt": "Umbraco icon" }
};
