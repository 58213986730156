import React, { useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import HomePageBanner from '../../components/Banners//HomePageBanner/HomePageBanner';
import PageContent from '../Layout/PageContent/PageContent';
import Section from '../Layout/ContentSection/ContentSection';
import Paragraph from '../../components/Paragraph/Paragraph';
import profilePic from '../../assets/images/ls/ls1.jpg';
import classes from './Home.module.scss';

const Home = (props) => {
    
    useEffect(props.setToolbarTheme, []);

    return (
        <React.Fragment>
            <HomePageBanner theme="night-sky">Welcome</HomePageBanner>
            <PageContent>
                <Section>
                    <ScrollAnimation animateIn={classes.SlideIn} animateOnce>
                        <Paragraph image={profilePic} imageAlt="an image">
                            <p>
                                Welcome to my website. I'm a professional web developer with a passion for creating nice things.
                            </p>
                            <p>
                                Feel free to take a look at my journey as a web developer,
                                the technologies that I have been known to busy myself with
                                and my publicly available projects by visiting the links above.
                            </p>
                            <p>
                                - Lourenco
                            </p>
                        </Paragraph>
                    </ScrollAnimation>
                </Section>
            </PageContent>
        </React.Fragment>
    );
};

export default Home;