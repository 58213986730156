import React, { useState, useEffect } from 'react';

import classes from './ProjectDynamicDisplay.module.scss';
import InfoTags from '../../Tags/InfoTags/InfoTags';
import CircleButton from '../../UI/Buttons/Circle/Circle';
import RoundedEdgeButton from '../../UI/Buttons/RoundedEdge/RoundedEdge';

const ProjectDynamicDisplay = (props) => {

    const imageContent = (
        <div className={classes.ImageContent}>
            <div className={classes.Image} style={{backgroundImage: `url(${props.image.thumbnail})`}}></div>
        </div>
    );

    const descriptionContent = (
        <div className={classes.DescriptionContent}>
            {props.description}
        </div>
    );
    
    const techUsedContent = (
        <div className={classes.TechUsedContent}>
            <h3>Tech used</h3>
            <InfoTags tags={props.techUsed} />
        </div>
    );

    const [contentToDisplay, setContentToDisplay] = useState(imageContent);
    const [activeButton, setActiveButton] = useState('image');
    const [contentMainClasses, setContentMainClasses] = useState([classes.Content]);
    const [isContentFadingIn, setIsContentFadingIn] = useState(false);

    const contentSwitchHandler = (content) => {
        if (content === activeButton)
            return;

        switch(content) {
            case 'image':
                setContentToDisplay(imageContent);
                setActiveButton('image');
                animateContentFadeIn();
                break;
            case 'description':
                setContentToDisplay(descriptionContent);
                setActiveButton('description');
                animateContentFadeIn();
                break;
            case 'techUsed':
                setContentToDisplay(techUsedContent);
                setActiveButton('techUsed');
                animateContentFadeIn();
                break;
            default:
                setContentToDisplay('The default condition was entered');
        }
    };

    const animateContentFadeIn = () => {
        setContentMainClasses((prevState) => {
            return [...prevState, classes.FadeInContent];
        });
        setIsContentFadingIn(true);
    }
    
    const removeTemporaryClasses = () => {
        if (isContentFadingIn) {
            setTimeout(() => {
                setContentMainClasses(prevState => {
                    const newClasses = prevState.filter(cssClass => {
                        return cssClass !== classes.FadeInContent;
                    });
                    return newClasses;
                });
                setIsContentFadingIn(false);
            }, 400);
        }
        
    };
    useEffect(removeTemporaryClasses, [isContentFadingIn]);

    const isButtonActive = (button) => {
        return activeButton === button;
    };

    let links = props.links.map(link => {
        return (
            <RoundedEdgeButton key={link.name} {...link}>{link.name}</RoundedEdgeButton>
        );
    });
    

    return (
        <div className={classes.DynamicCard}>
            <section className={classes.Heading}>
                <div className={classes.TitleRow}>
                    <span className={classes.Title}>{props.title}</span>
                    <div className={[classes.Links, classes.DesktopOnly].join(' ')}>
                        {links}
                    </div>
                </div>
                <span className={classes.Subtitle}>{props.subtitle}</span>
                <div className={[classes.Links, classes.MobileOnly].join(' ')}>
                    {links}
                </div>
            </section>
            <section className={classes.ContentWrapper}>
                <div className={contentMainClasses.join(' ')}>
                    {contentToDisplay}
                </div>
                <div className={classes.ContentNavigation}>
                    <div className={classes.ButtonGroup}>
                        <CircleButton
                            clicked={() => contentSwitchHandler('image')}
                            faIcon="image"
                            colour="green"
                            helpText="View image"
                            active={isButtonActive('image')}/>
                        <CircleButton
                            clicked={() => contentSwitchHandler('description')}
                            faIcon="scroll"
                            helpText="View description"
                            active={isButtonActive('description')}/>
                        <CircleButton
                            clicked={() => contentSwitchHandler('techUsed')}
                            faIcon="laptop-code"
                            helpText="View technology used"
                            active={isButtonActive('techUsed')}/>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ProjectDynamicDisplay;