import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './RoundedEdge.module.scss';

const RoundedEdgeButton = (props) => {

    let buttonClasses = [classes.Button];

    switch(props.colour) {
        case 'blue':
            buttonClasses.push(classes.Blue);
            break;
        default:
            break;
    }
    
    let faIcon = null;
    if (props.faIcon) {

        faIcon = (
            <span className={classes.FaIcon}>
                <FontAwesomeIcon icon={props.faIcon} />
            </span>
        );
    }

    let button = null;
    if (props.url) {
        button =(
            <a className={buttonClasses.join(' ')}
                title={props.helpText}
                href={props.url}
                target="_blank"
                rel="noopener noreferrer">
                <span className={classes.Text}>{props.children}</span>
                {faIcon}
            </a>
        );
    } else {
        button = (
            <div className={buttonClasses.join(' ')} title={props.helpText} onClick={props.clicked}>
                <span className={classes.Text}>{props.children}</span>
                {faIcon}
            </div>
        );
    }

    return (button);
};

export default RoundedEdgeButton;