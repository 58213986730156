import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import classes from './WorkExp.module.scss';
import CircleWithBorder from '../../UI/Shapes/CircleWithBorder/CircleWithBorder';
import VerticalLine from '../../UI/Shapes/VerticalLine/VerticalLine';
import Accordion from '../../Accordions/SimpleAccordion/SimpleAccordion';
import InfoTags from '../../Tags/InfoTags/InfoTags';

const WorkExp = (props) => {
    return (
        <ScrollAnimation animateIn={classes.FadeIn} animateOnce>
            <div className={classes.WorkExp}>
                <section className={[classes.TimePeriod, classes.DesktopOnly].join(' ')}>
                    <span>{props.timePeriod}</span>
                </section>
                <section className={classes.TimelineGraphics}>
                    <div className={classes.CheckpointCircle}>
                        <CircleWithBorder borderWidth="thick" fillColour="white" />
                    </div>
                    <VerticalLine style={props.lineStyle} width="4" />
                </section>
                <section className={classes.MainInfo}>
                    <section className={[classes.TimePeriod, classes.MobileOnly].join(' ')}>
                        <span>{props.timePeriod}</span>
                    </section>
                    <section className={classes.Heading}>
                        <h3 className={classes.Title}>{props.title}</h3>
                        <h4 className={classes.Subtitle}>{props.subtitle}</h4>
                    </section>
                    <Accordion heading="Summary">
                        {props.summary}
                    </Accordion>
                    <Accordion heading="Technologies">
                        <InfoTags tags={props.techUsed} />
                    </Accordion>
                </section>
            
            </div>
        </ScrollAnimation>
    );
};

export default WorkExp;