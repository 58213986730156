import React from 'react';

import NavItem from './NavItem/NavItem';
import itemsList from './NavItems.json';
import classes from './NavItems.module.scss';

const NavItems = (props) => {

    const navItems = itemsList.map(item => {
        return (
            <NavItem
                key={item.name}
                link={item.link}
                exact={item.exact}
                toolbarTheme={props.toolbarTheme}
                scrollMilestone={props.scrollMilestone}
                pageChanged={props.pageChanged}>{item.name}</NavItem>
        );
    });

    return (
        <ul className={classes.NavItems}>
            {navItems}
        </ul>
    );
};

export default NavItems;