import React from 'react';

import logo from '../../assets/images/logos/logo-small.png';
import classes from './Logo.module.scss';

const logoClasses = [classes.Logo, 'LogoGlobalClass'];

const Logo = (props) => (
    <div className={logoClasses.join(' ')}>
        <img src={logo} alt="Logo" />
    </div>
);

export default Logo;