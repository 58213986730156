import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './DrawerToggle.module.scss';

const DrawerToggle = (props) => {

    let drawerToggleClasses = [classes.DrawerToggle];

    if (!props.scrollMilestone || props.scrollMilestone < 50) {
        switch(props.toolbarTheme) {
            case 'homepage': drawerToggleClasses.push(classes.HomePageTheme); break;
            case 'skills': drawerToggleClasses.push(classes.SkillsPageTheme); break;
            case 'journey': drawerToggleClasses.push(classes.JourneyPageTheme); break;
            case 'projects': drawerToggleClasses.push(classes.ProjectsPageTheme); break;
            default: break;
        }
    }

    return (
        <div className={drawerToggleClasses.join(' ')} onClick={props.clicked}>
            <FontAwesomeIcon icon="bars"/>
        </div>
    );
};

export default DrawerToggle;