import React from 'react';

import classes from './NightSky.module.scss';

const NightSky = (props) => {
    return (
        <div className={classes.NightSky}>
            <div className={classes.StarsSmall}></div>
            <div className={classes.StarsMedium}></div>
            <div className={classes.StarsLarge}></div>
            {/* Credit goes to https://codepen.io/saransh/pen/BKJun for helping me create this night sky banner */}
        </div>
    );
};

export default NightSky;