import React from 'react';

import classes from './HomePageBanner.module.scss';
import NightSky from './Themes/NightSky/NightSky';

const HomePageBanner = (props) => {

    let bannerClasses = [classes.Banner];
    let theme = null;

    switch(props.theme) {
        case 'night-sky':
            theme = <NightSky />;
            break;
        default:
            bannerClasses.push(classes.Default);
            break;
    }

    let titleTextProperties = {};
    if (props.titleShadow)
        titleTextProperties.className = classes.TitleShadow;

    return (
        <div className={classes.Banner}>
            <div className={classes.Title}>
                <span {...titleTextProperties}>{props.children}</span>
            </div>
            {theme}
        </div>
    );
};

export default HomePageBanner;