import React from 'react';

import classes from './CherryBlossoms.module.scss';
import PetalsCanvas from './CherryBlossomsCanvas';

const CherryBlossoms = (props) => {

    return (
        <div className={classes.CherryBlossoms}>
            <div className={classes.FaintTrees}></div>
            <div className={classes.Trees}></div>
            <div className={classes.Tint}></div>
            <div className={classes.Canvas}>
                <PetalsCanvas />
            </div>
        </div>
    );
};

export default CherryBlossoms;