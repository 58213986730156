import React from 'react';

import classes from './Paragraph.module.scss';

const Paragraph = (props) => {

    let paragraphClasses = [classes.Paragraph];

    let image = null;
    if (props.image) {

        paragraphClasses.push(classes.WithImage);

        image = (
            <div style={{background: `url(${props.image}) 50% 50% no-repeat`}} className={classes.Image}></div>
        );
    }

    return (
        <article className={paragraphClasses.join(' ')}>
            <div className={classes.Text}>
                {props.children}
            </div>
            {image}
        </article>
    );
};

export default Paragraph;