import React, { useRef, useEffect, useState } from 'react';

import classes from './CanvasStyles.module.scss';
import MovingStar from '../Models/MovingStar.model';
import StarPreRenderCanvas from './StarPreRenderCanvas';

const MovingStarsCanvas = (props) => {

    const mainCanvasRef = useRef(null);
    const preRenderedCanvasRefs = useRef({});

    const [starPreRenderValues, setStarPreRenderValues] = useState([]);
    const [starPreRenderCanvases, setStarPreRenderCanvases] = useState([]);
    const [mainCanvas, setMainCanvas] = useState(null);

    useEffect(initialiseCanvas, []);
    useEffect(createPreRenderCanvases, [starPreRenderValues]);
    useEffect(linkPreRenderCanvases);

    function initialiseCanvas() {
        const newMainCanvas = new Canvas(mainCanvasRef, preRenderedCanvasRefs, setStarPreRenderValues);
        setMainCanvas(newMainCanvas);

        // clear the canvas on component unmounting
        return newMainCanvas.clearCanvas;
    }

    function createPreRenderCanvases () {
        if (starPreRenderValues.length < 1)
            return;

        // create the pre-rendered canvases
        // these canvases will be created in the DOM
        preRenderedCanvasRefs.current = {};

        const preRenderCanvases = [];
        for (let key in starPreRenderValues) {
            const starVals = starPreRenderValues[key];

            preRenderCanvases.push(
                <StarPreRenderCanvas
                        key={key}
                        identifier={starVals.identifier}
                        preRenderedCanvasRefs={preRenderedCanvasRefs}
                        width={starVals.width}
                        height={starVals.height}
                        radius={starVals.radius}
                        blurRadius={starVals.blurRadius}
                        colour={starVals.colour}/>
            );
        }

        if (preRenderCanvases.length) {
            setStarPreRenderCanvases(preRenderCanvases);
        }
    }

    function linkPreRenderCanvases() {
        // link the ascending stars to their relevant cached pre-rendered canvases
        if (Object.keys(preRenderedCanvasRefs.current).length > 0) {
            mainCanvas.linkStarsToPreCanvases();
        }
    }

    return (
        <div className="MovingStarsCanvasWrapper">
            <canvas
                ref={mainCanvasRef}
                className={[classes.Canvas, classes.AnimationCanvas].join(' ')}>
            </canvas>
            <div className={classes.PreRenderedCanvases}>
                {starPreRenderCanvases}
            </div>
        </div>
    );
};

export default MovingStarsCanvas;

class Canvas {
    constructor(canvasRef, preRenderedCanvasRefs, setStarPreRenderValues) {
        this.canvas = canvasRef.current;
        this.canvas.width = canvasRef.current.offsetWidth;
        this.canvas.height = canvasRef.current.offsetHeight;
        this.ctx = canvasRef.current.getContext("2d");

        this.preRenderedCanvasRefs = preRenderedCanvasRefs;
        this.setStarPreRenderValues = setStarPreRenderValues;

        this.numMovingStars = 0;
        this.movingStars = [];
        this.preRenderValues = {};

        this.initCanvas();
    }

    initCanvas = () => {
        window.addEventListener("resize", this.handleWindowResize);
    
        this.setScreenWidthSpecificVariables();
    
        // initiate the objects here
        this.createMovingStars();
    
        // call the animate function here
        this.animate();
    };

    handleWindowResize = () => {
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
        // resize/reposition moving stars? - seems unnecessary for now
    };

    clearCanvas = () => {
        this.animate = () => null;
        window.removeEventListener("resize", this.handleWindowResize);
    };

    createMovingStars = () => {
        const radiusPossibilities = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
        for (let i = 0; i < this.numMovingStars; i++) {
            //const radius = Math.floor((Math.random() * 1.5) + 1);
            const radius = radiusPossibilities[Math.floor(Math.random() * radiusPossibilities.length)];
            const blurRadius = radius;
            const sideLength = (radius + blurRadius) * 3;
            const position = {
                x: Math.floor(Math.random() * this.canvas.width),
                y: Math.floor(Math.random() * this.canvas.height)
            };
            const velocity = {
                // x: (Math.random() * 0.08) - 0.09
                x: (Math.random() * 0.08) - 0.10
            };
            velocity.y = -(velocity.x/2);

            let values = null;
            const preCanvasIdentifier = `rad${radius}`;
            if (!this.preRenderValues[preCanvasIdentifier]) {

                values = {
                    identifier: preCanvasIdentifier,
                    width: sideLength,
                    height: sideLength,
                    radius: radius,
                    colour: '#fff',
                    blurRadius: blurRadius
                };

                this.preRenderValues[preCanvasIdentifier] = values;
            }
    
            this.movingStars.push(
                new MovingStar(this.canvas, this.ctx, position, radius, '#fff', velocity, preCanvasIdentifier)
            );
        }

        // put everything in the starPreRenderCanvases array
        // these will eventually get created as canvas elements in the DOM
        this.setStarPreRenderValues(this.preRenderValues);
    };

    linkStarsToPreCanvases = () => {
        this.movingStars.forEach(star => {
            const matchingPreCanvas = this.preRenderValues[star.preRenderedCanvasIdentifier];

            if (matchingPreCanvas) {
                star.preRenderedCanvas = this.preRenderedCanvasRefs.current[matchingPreCanvas.identifier];
            }
        });
    };

    animate = () => {
        requestAnimationFrame(this.animate);
    
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    
        // call the update function for each object here
        this.movingStars.forEach(star => {
            star.update();
        });
    };

    setScreenWidthSpecificVariables = () => {    
        if (this.canvas.width >=1600) {
            this.numMovingStars = 110;
        } else if (this.canvas.width >= 1200) {
            this.numMovingStars = 90;
        } else if (this.canvas.width >= 800) {
            this.numMovingStars = 70;
        } else if (this.canvas.width >= 600) {
            this.numMovingStars = 50;
        } else {
            this.numMovingStars = 30;
        }
    };

};