import React from 'react';

import classes from './Book.module.scss';
import imgBook from './images/glowing-book.png';
import Canvas from './Canvas/BookCanvas';

const Book = (props) => {

    return (
        <div className={classes.BookTheme}>
            <div className={classes.BookWrapper}>
                <div className={classes.BookImage}>
                    <img src={imgBook} alt="open book" />
                </div>
            </div>
            <div className={classes.BookThemeCanvas}>
                <Canvas />
            </div>
        </div>
    );
};

export default Book;