import React, { useEffect, useRef, useState } from 'react';

import classes from './BookCanvas.module.scss';
import SpherePreRenderCanvas from './SpherePreRenderCanvas';

const BookCanvas = (props) => {

    const animationCanvasRef = useRef(null);
    const preCanvasRefs = useRef({});

    const [spherePreRenderValues, setSpherePreRenderValues] = useState([]);
    const [spherePreRenderCanvases, setSpherePreRenderCanvases] = useState([]);
    const [animationCanvas, setAnimationCanvas] = useState(null);

    useEffect(initialiseAnimationCanvas, []);
    useEffect(createPreRenderCanvases, [spherePreRenderValues]);
    useEffect(linkPreRenderCanvases);

    function initialiseAnimationCanvas() {
        // initialise the main canvas
        const newMainCanvas = new AnimationCanvas(animationCanvasRef, preCanvasRefs, setSpherePreRenderValues);
        setAnimationCanvas(newMainCanvas);

        // clear the canvas on component unmounting
        return newMainCanvas.clearCanvas;
    }

    function createPreRenderCanvases () {
        if (spherePreRenderValues.length < 1)
            return;

        // create the pre-rendered canvases
        // these canvases will be created in the DOM
        preCanvasRefs.current = {};

        const preCanvases = spherePreRenderValues.map((obj, i) => {

            return (
                <SpherePreRenderCanvas
                        key={i}
                        identifier={obj.identifier}
                        preCanvasRefs={preCanvasRefs}
                        width={obj.width}
                        height={obj.height}
                        radius={obj.radius}
                        blurRadius={obj.blurRadius}
                        colour={obj.colour}/>
            );
        });

        if (preCanvases.length) {
            setSpherePreRenderCanvases(preCanvases);
        }
    }

    function linkPreRenderCanvases() {
        // link the ascending spheres to their relevant cached pre rendered canvases
        if (Object.keys(preCanvasRefs.current).length > 0) {
            animationCanvas.linkSpheresToPreCanvases();
        }
    }

    return (
        <div className={classes.BookCanvasWrapper}>
            <canvas
                ref={animationCanvasRef}
                className={classes.AnimationCanvas}>
            </canvas>
            <div className={classes.PreRenderedCanvases}>
                {spherePreRenderCanvases}
            </div>
        </div>
    );
};

export default BookCanvas;

class AnimationCanvas {
    constructor(animationCanvasRef, preCanvasRefs, setSpherePreRenderValues) {
        this.canvas = animationCanvasRef.current;
        this.canvas.width = animationCanvasRef.current.offsetWidth;
        this.canvas.height = animationCanvasRef.current.offsetHeight;
        
        this.ctx = animationCanvasRef.current.getContext("2d");

        this.preCanvasRefs = preCanvasRefs;
        this.setSpherePreRenderValues = setSpherePreRenderValues;

        this.numAscendingSpheres = 0;
        this.ascendingSpheres = [];
        this.spherePreRenderValues = [];
        this.uniqueSpheres = {
            // format is
            /*
                radius : {
                    color : <canvas />
                }
            */
        };
        this.respawnRangeY = 20;

        this.initCanvas();
    };
    
    sphereColours = [
        '#fff', //white
        '#fff', //white
        '#fff5bf', //light yellow
        '#d2ff9c', //light green
        '#a1ffe9', //light teal
        '#7dc4ff', //light blue
        '#aa7dff', //light purple
        '#ffadfb', //light pink  
        '#ff9494' //light red
    ];
    
    initCanvas = () => {
        window.addEventListener("resize", this.handleWindowResize);

        this.setScreenWidthSpecificVariables();

        // initiate the objects here
        this.createAscendingSpheres();
    
        // call the animate function here
        this.animate();
    };

    handleWindowResize = () => {
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
        this.setRespawnRangeY();
        this.updateSpheresRespawnRangeY();
    };

    clearCanvas = () => {
        // stop the animate function
        this.animate = () => (null);
        window.removeEventListener("resize", this.handleWindowResize);
    };

    animate = () => {
        requestAnimationFrame(this.animate);
    
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    
        // call the update function for each object here
        this.ascendingSpheres.forEach(sphere => {
            sphere.update();
        });
    };

    createAscendingSpheres = () => {
        const radiusPossibilities = [0.5, 0.75, 0.75, 1, 1, 1, 1.25, 1.25, 1.5, 1.75, 2];

        for (let i = 0; i < this.numAscendingSpheres; i++) {
            // const radius = Math.floor((Math.random() * 2.1) + 1);
            const radius = radiusPossibilities[Math.floor((Math.random() * radiusPossibilities.length))];
            const blurRadius = radius * 3;
            const sideLength = (radius + blurRadius) * 3;
            const x = Math.floor(Math.random() * (this.canvas.width-(sideLength)));
            const y = Math.floor(Math.random() * (this.canvas.height-(sideLength)));
            const yVelocity = (Math.random() * 0.17) + 0.08;
            const colour = this.sphereColours[Math.floor(Math.random() * this.sphereColours.length)];
    
            // push the unique radius-colour combination spheres into
            // an array that holds canvas elements

            let preRenderValues = null;
            const preCanvasIdentifier = `r${radius}c${colour.replace('#', '')}`;
            if (!this.uniqueSpheres[radius]) {
                this.uniqueSpheres[radius] = {};
            }
            if (!this.uniqueSpheres[radius][colour]) {
                this.uniqueSpheres[radius][colour] = true;

                preRenderValues = {
                    identifier: preCanvasIdentifier,
                    width: sideLength,
                    height: sideLength,
                    radius: radius,
                    colour: colour,
                    blurRadius: blurRadius
                };

                this.spherePreRenderValues.push(preRenderValues);
            }

            this.ascendingSpheres.push(new AscendingSphere(this.canvas, this.ctx, x, y, yVelocity, this.respawnRangeY, preCanvasIdentifier));
        }

        // put everything in the spherePreRenderCanvases array
        // these will eventually get created as canvas elements in the DOM
        this.setSpherePreRenderValues(this.spherePreRenderValues);
    };

    linkSpheresToPreCanvases = () => {
        this.ascendingSpheres.forEach(sphere => {
            const matchingPreCanvas = this.spherePreRenderValues.find(obj => {
                return sphere.preCanvasIdentifier === obj.identifier;
            });

            if (matchingPreCanvas)
                sphere.preCanvas = this.preCanvasRefs.current[matchingPreCanvas.identifier];

        });
    };

    setScreenWidthSpecificVariables = () => {
        if (this.canvas.width >=380) {
            this.numAscendingSpheres = 60;
        } else if (this.canvas.width >= 320) {
            this.numAscendingSpheres = 50;
        } else if (this.canvas.width >= 220) {
            this.numAscendingSpheres = 40;
        } else {
            this.numAscendingSpheres = 30;
        }
        this.setRespawnRangeY();
    };

    setRespawnRangeY = () => {
        if (this.canvas.width >=380) {
            this.respawnRangeY = 20;
        } else if (this.canvas.width >= 320) {
            this.respawnRangeY = 15;
        } else if (this.canvas.width >= 220) {
            this.respawnRangeY = 10;
        } else {
            this.respawnRangeY = 5;
        }
    };

    updateSpheresRespawnRangeY = (() => {
        let updateInProgress = false;

        function update() {
            if (!updateInProgress) {

                updateInProgress = true;

                this.ascendingSpheres.forEach(sphere => {
                    sphere.respawnRangeY = this.respawnRangeY;
                });
                
                updateInProgress = false;
            }
        }
        
        return update;
    })();
};

class Sphere {
    constructor(canvas, ctx, x, y, preCanvasIdentifier) {
        this.canvas = canvas;
        this.ctx = ctx;
        this.x = x;
        this.y = y;
        this.preCanvasIdentifier = preCanvasIdentifier;
        this.preCanvas = null;
    }

    draw() {
        if (this.preCanvas)
            this.ctx.drawImage(this.preCanvas, this.x, this.y);
    };

    update() {
        this.draw();
    };
}

class AscendingSphere extends Sphere {
    constructor(canvas, ctx, x, y, yVelocity, respawnRangeY, preCanvasIdentifier) {
        super(canvas, ctx, x, y, preCanvasIdentifier);

        this.yVelocity = yVelocity;
        this.respawnRangeY = respawnRangeY;
    }

    move() {
        this.y -= this.yVelocity;
    };

    respawn() {
        // respawn sphere from the bottom
        if (this.preCanvas && this.y + this.preCanvas.height <= 0) {
            this.x = Math.floor(Math.random() * (this.canvas.width-(this.preCanvas.width)));
            this.y = Math.floor(this.canvas.height - (Math.random() * this.respawnRangeY) - this.preCanvas.height);
        }
    };

    update() {
        this.respawn();
        this.move();
        super.update();
    };
}