import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import classes from './Layout.module.scss';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Footer from '../../components/Footer/Footer';

import Home from '../HomePage/Home';
import Projects from '../ProjectsPage/Projects';
import Skills from '../SkillsPage/Skills';
import Journey from '../JourneyPage/Journey';

const Layout = (props) => {
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [toolbarTheme, setToolbarTheme] = useState(null);
    const [mainContentClasses, setMainContentClasses] = useState([classes.Content]);

    const sideDrawerClosedHandler = () => {
        setShowSideDrawer(false);
    };
    const sideDrawerToggleHandler = () => setShowSideDrawer(prevState => {
        return !prevState;
    });

    const toolbarThemeHandler = (page) => {
        setToolbarTheme(page);
    };

    const pageChangedHandler = () => {
        // fade in transition when opening a page
        
        setMainContentClasses([...mainContentClasses, classes.FadingIn]);
        window.scrollTo(0, 0);

        setTimeout(() => {
            let arrayCopy = [...mainContentClasses];
            const index = arrayCopy.indexOf(classes.FadingIn);
            if (index > -1)
                arrayCopy.splice(index, 1);
            else
                arrayCopy = [classes.Content];

            setMainContentClasses(arrayCopy);

        }, 500);
    };

    return (
        <React.Fragment>
            <Toolbar
                drawerToggleClicked={sideDrawerToggleHandler} 
                theme={toolbarTheme}
                pageChanged={pageChangedHandler}/>
            <SideDrawer
                open={showSideDrawer}
                closed={sideDrawerClosedHandler}
                pageChanged={pageChangedHandler}/>
            <main className={mainContentClasses.join(' ')}>
                <Switch>
                    <Route path="/skills">
                        <Skills setToolbarTheme={() => toolbarThemeHandler('skills')}/>
                    </Route>
                    <Route path="/projects">
                        <Projects setToolbarTheme={() => toolbarThemeHandler('projects')}/>
                    </Route>
                    <Route path="/journey">
                        <Journey setToolbarTheme={() => toolbarThemeHandler('journey')}/>
                    </Route>
                    <Route path="/">
                        <Home setToolbarTheme={() => toolbarThemeHandler('homepage')}/>
                    </Route>
                </Switch>
                {/* {props.children} */}
            </main>
            <Footer />
        </React.Fragment>
    );
};

export default Layout;