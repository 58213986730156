import React, { useEffect } from 'react';

const StarPreRenderCanvas = (props) => {

    useEffect(() => {
        const ctx = props.preRenderedCanvasRefs.current[props.identifier].getContext('2d');

        ctx.beginPath();
        ctx.arc(props.width/2, props.height/2, props.radius, 0, Math.PI * 2);
        ctx.shadowColor = props.colour;
        ctx.shadowBlur = props.blurRadius;
        ctx.fillStyle = props.colour;
        ctx.fill();
        ctx.closePath();
    }, [props.radius, props.colour, props.preRenderedCanvasRefs, props.identifier,
        props.width, props.height, props.blurRadius]);

    return (
        <canvas
            identifier={props.identifier}
            ref={el => props.preRenderedCanvasRefs.current[props.identifier] = el}
            width={props.width}
            height={props.height}>
        </canvas>
    );
};

export default StarPreRenderCanvas;