import React from 'react';

import WorkExp from './WorkExp/WorkExp';
import classes from './WorkExpList.module.scss';

const WorkExpList = (props) => {

    let title;
    if (props.title)
        title = <h2 className={classes.Title}>{props.title}</h2>;

    const workExperiences = props.list.map((workExp, index) => {
        return (
            <li key={index}>
                <WorkExp {...workExp} />
            </li>
        );
    });

    return (
        <React.Fragment>
            {title}
            <ul className={classes.WorkExpList}>
                {workExperiences}
            </ul>
        </React.Fragment>
        
    );
};

export default WorkExpList;