import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './CloseDrawer.module.scss';

const CloseDrawer = (props) => {

    return (
        <div className={classes.CloseDrawer} onClick={props.clicked}>
            <FontAwesomeIcon icon="times" />
        </div>
    );    
};

export default CloseDrawer;