import React from 'react';

import classes from './ProjectList.module.scss';
import ProjectDynamicDisplay from './ProjectDynamicDisplay/ProjectDynamicDisplay';

const ProjectList = (props) => {

    const projects = props.projects.map(project => {
        return (
            <li key={project.title} className={classes.ProjectListItem}>
                <ProjectDynamicDisplay {...project}/>
            </li>
        );
    });

    return (
        <ul className={classes.ProjectList}>
            {projects}
        </ul>
    );
};

export default ProjectList;