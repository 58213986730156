import React from 'react';

import classes from './PlaneJourney.module.scss';

const PlaneJourney = (props) => {

    return (
        <div className={[classes.PlaneJourney, classes.Load].join(' ')}>
            <div className={classes.PlaneWrapper}>
                <div className={classes.Plane}>
                    <div className={[classes.NavigationLights, classes.GreenNavLight].join(' ')}></div>
                    <div className={[classes.WhiteAntiCollisionLights, classes.WingWhiteLight].join(' ')}></div>
                    <div className={[classes.WhiteAntiCollisionLights, classes.TailWhiteLight].join(' ')}></div>
                    <div className={[classes.RedAntiCollisionLights, classes.TopRedLight].join(' ')}></div>
                    <div className={[classes.RedAntiCollisionLights, classes.BottomRedLight].join(' ')}></div>
                </div>
            </div>
            <div className={[classes.Clouds, classes.Cloud1].join(' ')}></div>
            <div className={[classes.Clouds, classes.Cloud2].join(' ')}></div>
            <div className={[classes.Clouds, classes.Cloud3].join(' ')}></div>
            <div className={[classes.Clouds, classes.Cloud4].join(' ')}></div>
            <div className={[classes.Clouds, classes.Cloud5].join(' ')}></div>
        </div>
    );
};

export default PlaneJourney;