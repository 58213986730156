import React, { useEffect, useState } from 'react';

const MeteorPreRenderCanvas = (props) => {
    //props are
    /*
        identifier, preRenderedCanvasRefs, width, height
        dimensions { headRadius, trailLength, angle, direction, blurRadius },
        colours { glowColour, headColour, trailColour },
        velocity, timings { firstSpawnDelay, respawnInterval }
    */
    const [ctx, setCtx] = useState(null);

    const triggerProps = [
        ctx,
        props.dimensions.headRadius,
        props.dimensions.trailLength,
        props.dimensions.angle,
        props.dimensions.direction,
        props.colours.glowColour,
        props.colours.headColour,
        props.colours.trailColour
    ];

    useEffect(setContext, []);
    useEffect(drawMeteor, triggerProps);

    function setContext() {
        setCtx(props.preRenderedCanvasRefs.current[props.identifier].getContext('2d'));
    }

    function drawTail() {
        ctx.save();
        ctx.translate(props.width - props.dimensions.headRadius * 4, props.height - props.dimensions.headRadius * 4);
        
        let gradient = ctx.createLinearGradient(0, 0, -props.dimensions.trailLength, 0);
        gradient.addColorStop('0', 'rgb(181, 235, 255)');
        gradient.addColorStop('1.0', 'rgba(181, 235, 255, 0)');
        
        ctx.rotate(props.dimensions.direction);

        ctx.strokeStyle = gradient;
        ctx.shadowColor = props.colours.trailColour;
        ctx.shadowBlur = props.dimensions.headRadius;
        ctx.lineCap = 'round';
        ctx.lineWidth = props.dimensions.headRadius * 1.5;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(-props.dimensions.trailLength, 0);
        ctx.stroke();

        ctx.restore();
    }

    function drawHeadBurn() {
        ctx.save();
        ctx.translate(props.width - props.dimensions.headRadius * 4, props.height - props.dimensions.headRadius * 4);
        ctx.rotate(props.dimensions.direction);
        ctx.scale(4, 1.75);
        ctx.beginPath();
        ctx.arc(-(props.dimensions.headRadius * 0.4), 0, props.dimensions.headRadius, 0, Math.PI * 2);
        ctx.restore();

        ctx.shadowColor = props.colours.trailColour;
        ctx.shadowBlur = props.dimensions.headRadius;
        ctx.fillStyle = props.colours.trailColour;
        ctx.fill();
    }

    function drawHead() {
        ctx.save();
        ctx.translate(props.width - props.dimensions.headRadius * 4, props.height - props.dimensions.headRadius * 4);
        ctx.rotate(props.dimensions.direction);
        ctx.scale(1.75, 1);
        ctx.beginPath();
        ctx.arc(0, 0, props.dimensions.headRadius, 0, Math.PI * 2);
        ctx.restore();

        ctx.shadowColor = props.colours.glowColour;
        ctx.shadowBlur = props.dimensions.headRadius;
        ctx.fillStyle = props.colours.headColour;
        ctx.fill();
        ctx.closePath();
    }

    function drawMeteor() {
        if (ctx) {
            drawTail();
            drawHeadBurn();
            drawHead();
        }
    }

    return (
        <canvas
            identifier={props.identifier}
            ref={el => props.preRenderedCanvasRefs.current[props.identifier] = el}
            width={props.width}
            height={props.height}>
        </canvas>
    );
};

export default MeteorPreRenderCanvas;