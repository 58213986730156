import petal1 from './petal-1.svg';
import petal2 from './petal-2.svg';
import petal3 from './petal-3.svg';
import petal4 from './petal-4.svg';
import petal5 from './petal-5.svg';
import petal6 from './petal-6.svg';
import petal1Flipped from './petal-1-flipped.svg';
import petal2Flipped from './petal-2-flipped.svg';
import petal3Flipped from './petal-3-flipped.svg';
import petal4Flipped from './petal-4-flipped.svg';
import petal5Flipped from './petal-5-flipped.svg';
import petal6Flipped from './petal-6-flipped.svg';

const petalImages = {
    petal1,
    petal2,
    petal3,
    petal4,
    petal5,
    petal6,
    petal1Flipped,
    petal2Flipped,
    petal3Flipped,
    petal4Flipped,
    petal5Flipped,
    petal6Flipped
};

export default petalImages;