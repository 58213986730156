import React from 'react';
import classes from './VerticalLine.module.scss';

const lineColours = {
    green: classes.Green,
    blue: classes.Blue,
    black: classes.Black
};

const lineStyles = {
    solid: classes.Solid,
    dotted: classes.Dotted,
    dashsed: classes.Dashed
};

const VerticalLine = (props) => {

    let lineClasses = [classes.Line];
    let inlineStyle = {};

    if (props.colour && lineColours[props.colour])
        lineClasses.push(lineColours[props.colour]);

    if (props.style && lineStyles[props.style])
        lineClasses.push(lineStyles[props.style]);

    if (props.width) {
        const width = props.width;
        const marginLeft = width/2;
        inlineStyle.borderLeftWidth = width.toString() + 'px';
        inlineStyle.marginLeft = '-' + marginLeft.toString() + 'px';
    }

    return (
        <div style={inlineStyle} className={lineClasses.join(' ')}></div>
    );
};

export default VerticalLine;