import React, { useRef, useEffect, useState } from 'react';

import classes from './CanvasStyles.module.scss';
import Star from '../Models/Star.model';

const StaticStarsCanvas = (props) => {

    const canvasRef = useRef(null);

    const [canvas, setCanvas] = useState(null);

    useEffect(initialiseCanvas, []);

    function initialiseCanvas() {
        const newCanvas = new Canvas(canvasRef);
        setCanvas(newCanvas);

        // clear the canvas on component unmounting
        return newCanvas.clearCanvas;
    }

    return (
        <canvas
            ref={canvasRef}
            className={[classes.Canvas, classes.StaticCanvas].join(' ')}>
        </canvas>
    );
};

export default StaticStarsCanvas;

class Canvas {
    constructor(canvasRef) {
        this.canvas = canvasRef.current;
        this.canvas.width = canvasRef.current.offsetWidth;
        this.canvas.height = canvasRef.current.offsetHeight;
        this.ctx = canvasRef.current.getContext("2d");
        
        this.numBackgroundStars = 0;
        this.backgroundStars = [];

        this.initCanvas();
    }

    initCanvas = () => {
        window.addEventListener("resize", this.handleWindowResize);

        this.setScreenWidthSpecificVariables();
        this.createBackgroundStars();
        this.draw();
    };

    handleWindowResize = () => {
        const oldCanvasWidth = this.canvas.width;
        const oldCanvasHeight = this.canvas.height;

        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;

        // refresh the background stars
        this.refreshStarDimensions(oldCanvasWidth, oldCanvasHeight);
    };

    clearCanvas = () => {
        window.removeEventListener("resize", this.handleWindowResize);
    };

    createBackgroundStars = () => {
        for (let i = 0; i < this.numBackgroundStars; i++) {
            const radius = (Math.random() * 1.0) + 0.2;
            const position = {
                x: Math.random() * this.canvas.width,
                y: Math.random() * this.canvas.height
            };
    
            this.backgroundStars.push(new Star(this.canvas, this.ctx, position, radius, '#fff'));
        }
    };

    draw = () => {
        this.backgroundStars.forEach(star => {
            star.draw();
        });
    };

    refreshStarDimensions = (() => {
        let refreshInProgress = false;
        
        function refresh(oldCanvasWidth, oldCanvasHeight) {

            if (this.canvas && this.canvas.width && this.canvas.height && oldCanvasWidth && oldCanvasHeight) {
                const widthRatio = this.canvas.width / oldCanvasWidth;
                //const heightRatio = this.canvas.height / oldCanvasHeight;

                if (!refreshInProgress) {
                    refreshInProgress = true;
    
                    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    
                    this.backgroundStars.forEach(star => {
                        star.x = star.x * widthRatio;
                        //star.y = star.y * heightRatio;
                        star.draw();
                    });
                    
                    refreshInProgress = false;
                }
            }

        }
        
        return refresh;
    })();

    setScreenWidthSpecificVariables = () => {    
        if (this.canvas.width >=1600) {
            this.numBackgroundStars = 600;
        } else if (this.canvas.width >= 1200) {
            this.numBackgroundStars = 500;
        } else if (this.canvas.width >= 800) {
            this.numBackgroundStars = 400;
        } else if (this.canvas.width >= 600) {
            this.numBackgroundStars = 300;
        } else {
            this.numBackgroundStars = 200;
        }
    };
};