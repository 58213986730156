import React from 'react';

import classes from './SideDrawer.module.scss';
import Logo from '../../Logo/Logo';
import NavItems from '../NavItems/NavItems';
import Backdrop from '../../UI/Backdrop/Backdrop';
import CloseDrawer from './CloseDrawer/CloseDrawer';

const SideDrawer = (props) => {

    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }

    const navClickHandler = () => {
        props.closed();
        props.pageChanged();
    };

    return (
        <React.Fragment>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ')}>
                <header className={classes.Header}>
                    <div className={classes.LogoWrapper}>
                        <Logo />
                    </div>
                    <div className={classes.CloseDrawerWrapper}>
                        <CloseDrawer clicked={props.closed}/>
                    </div>
                </header>
                <main className={classes.Main}>
                    <nav onClick={navClickHandler}>
                        <NavItems />
                    </nav>
                </main>
            </div>
        </React.Fragment>
    );
};

export default SideDrawer;