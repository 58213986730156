import React from 'react';

import classes from './CircleWithBorder.module.scss';

const borderColours = {
    green: classes.GreenBorder,
    blue: classes.BlueBorder
};

const borderWidths = {
    thin: classes.ThinBorder,
    medium: classes.MediumBorder,
    thick: classes.ThickBorder
};

const fillColours = {
    white: classes.WhiteFill,
    blue: classes.BlueFill,
    green: classes.GreenFill
};

const circleSizes = {
    small: classes.SmallCircle,
    medium: classes.MediumCircle,
    large: classes.LargeCircle
};

const CircleWithBorder = (props) => {
    
    let circleClasses = [classes.CircleWithBorder];

    if (props.borderColour && borderColours[props.borderColour])
        circleClasses.push(borderColours[props.borderColour]);
    
    if (props.borderWidth && borderWidths[props.borderWidth])
        circleClasses.push(borderWidths[props.borderWidth]);

    if (props.fillColour && fillColours[props.fillColour])
        circleClasses.push(fillColours[props.fillColour]);

    if (props.circleSize && circleSizes[props.circleSize])
        circleClasses.push(circleSizes[props.circleSize]);
    
    return (
        <div className={circleClasses.join(' ')}></div>
    );
};

export default CircleWithBorder;