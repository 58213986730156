import Star from './Star.model';

class MovingStar extends Star {
    constructor(canvas, ctx, position, radius, colour, velocity, preCanvasIdentifier) {
        super(canvas, ctx, position, radius, colour, preCanvasIdentifier);

        this.xVelocity = velocity.x;
        this.yVelocity = velocity.y;
    }

    move() {
        this.x += this.xVelocity;
        this.y += this.yVelocity;
    }

    respawn() {
        if (this.preRenderedCanvas) {
            if (this.x + this.preRenderedCanvas.width <= 0 || this.y >= this.canvas.height) {
                const respawnEdge = Math.ceil(Math.random() * 2);
                
                if (respawnEdge === 1) {
                    // respawn star from the top
                    this.x = Math.floor(Math.random() * this.canvas.width);
                    this.y = -this.preRenderedCanvas.height;
                } else {
                    // respawn star from the right side
                    this.x = this.canvas.width + this.radius;
                    this.y = Math.floor(Math.random() * this.canvas.height);
                }
            }
        }
    }

    update() {
        this.respawn();
        this.move();
        super.update();
    }
}

export default MovingStar;