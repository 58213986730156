import React, { useEffect } from 'react';

import Banner from '../../components/Banners/Banner/Banner';
import PageContent from '../Layout/PageContent/PageContent';
import Section from '../Layout/ContentSection/ContentSection';
import ProjectList from '../../components/ProjectList/ProjectList';

import gaProjects from './GAProjects.config';

const Projects = (props) => {

    useEffect(props.setToolbarTheme, []);

    return (
        <React.Fragment>
            <Banner
                theme="cherry-blossoms"
                titleShadow={true}>Projects</Banner>
            <PageContent>
                <Section>
                    <h2>Projects at General Assembly</h2>
                    <p>These projects were created on my Web Development Immersive course at General Assembly between March and June 2017.</p>
                    <ProjectList projects={gaProjects}/>
                </Section>
            </PageContent>
        </React.Fragment>
    );
};

export default Projects;