class Petal {
    constructor(canvasProps, img, x, y, width, height, physics) {
        this.canvas = canvasProps.canvas;
        this.ctx = canvasProps.ctx;
        this.img = img;
        this.width = width;
        this.height = height;
        this.originalX = x;
        this.x = x;
        this.y = y;
        this.dy = physics.dy;
        this.swayVariance = physics.sway;
        this.period = physics.swayPeriod;
        this.interval = Math.floor((Math.random() * physics.swayPeriod));
        this.rotationVarianceRads = physics.rotation * (Math.PI/180);
    }

    draw = function () {
        this.ctx.drawImage(this.img, this.x, this.y, this.width, this.height);
    };
    drawRotated = function (x, y) {
        this.ctx.drawImage(this.img, x, y, this.width, this.height);
    };

    update = function () {
        this.fall();
        this.sway();

        this.draw();
        //this.rotateAndDraw();

        if (this.interval < this.period)
            this.interval++;
        else {
            this.interval = 0;
        }
    };

    resetXPosition = function() {
        const x = Math.floor(Math.random() * (this.canvas.width - this.width));
        this.originalX = x;
        this.x = x;
    };

    fall = function () {
        if (this.y <= this.canvas.height) {
            this.y += this.dy;
        }
        else {
            this.y = -this.height;
            this.resetXPosition();
        }
    };
    sway = function () {
        this.x = this.originalX + this.swayVariance * Math.sin((this.interval / (this.period / 2)) * Math.PI);
    };
    rotateAndDraw = function () {
        this.ctx.save();
        this.ctx.translate(this.x + this.width/2, this.y + this.height/2);
        this.ctx.rotate(-1 * ((this.rotationVarianceRads) * Math.sin((this.interval / (this.period / 2)) * Math.PI)));
        this.drawRotated(-this.width / 2, -this.height / 2);
        this.ctx.restore();
    };
}

export default Petal;