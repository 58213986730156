import React, { useEffect } from 'react';

import Banner from '../../components/Banners/Banner/Banner';
import PageContent from '../Layout/PageContent/PageContent';
import Section from '../Layout/ContentSection/ContentSection';
import WorkExpList from '../../components/WorkExpList/WorkExpList';
import workExperience from './WorkExperience.config';

const Journey = (props) => {

    useEffect(props.setToolbarTheme, []);

    return (
        <React.Fragment>
            <Banner theme="plane-journey" titleShadow={true}>Web Development Journey</Banner>
            <PageContent>
                <Section>
                    <WorkExpList list={workExperience}/>
                </Section>
            </PageContent>
        </React.Fragment>
    );
};

export default Journey;