import React from 'react';

import classes from './InfoTags.module.scss';
import InfoTag from './InfoTag/InfoTag';

const InfoTags = (props) => {

    const tags = props.tags.map((tag, index) => (
        <InfoTag key={index} {...tag}/>
    ));

    return (
        <ul className={classes.Tags}>
            {tags}
        </ul>
    );
};

export default InfoTags;