import React from 'react';
// import { Switch, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faImage, faGlobe, faScroll, faLaptopCode, faBookOpen,
  faChevronUp, faChevronDown, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faGithubSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import './styles/reset.css';
import './App.scss';

import Layout from './containers/Layout/Layout';
// import Home from './containers/HomePage/Home';
// import Projects from './containers/ProjectsPage/Projects';
// import Skills from './containers/SkillsPage/Skills';
// import Journey from './containers/JourneyPage/Journey';

library.add(faImage, faGlobe, faScroll, faLaptopCode, faBookOpen,
  faChevronUp, faChevronDown, faBars, faTimes, faGithubSquare, faLinkedin);

function App() {
  return (
    <div className="App">
      <Layout>
        {/* <Switch>
          <Route path="/skills"><Skills /></Route>
          <Route path="/projects"><Projects /></Route>
          <Route path="/journey"><Journey /></Route>
          <Route path="/"><Home /></Route>
        </Switch> */}
      </Layout>
    </div>
  );
}

export default App;
