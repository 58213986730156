class Star {
    constructor(canvas, ctx, position, radius, colour, preCanvasIdentifier) {
        this.canvas = canvas;
        this.ctx = ctx;
        this.x = position.x;
        this.y = position.y;
        this.radius = radius;
        this.colour = colour;
        
        this.preRenderedCanvasIdentifier = preCanvasIdentifier;
        this.preRenderedCanvas = null;
    }

    draw() {
        this.ctx.beginPath();
        this.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        this.ctx.shadowColor = this.colour;
        this.ctx.shadowBlur = this.radius * 1;
        this.ctx.fillStyle = this.colour;
        this.ctx.fill();
        this.ctx.closePath();
    };

    drawPreRendered() {
        this.ctx.drawImage(this.preRenderedCanvas, this.x, this.y);
    }

    update() {
        if (this.preRenderedCanvas)
            this.drawPreRendered();
    };
}

export default Star;