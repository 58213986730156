import React from 'react';

import classes from './NightSky.module.scss';
import StaticStarsCanvas from './Canvases/StaticStarsCanvas';
import MovingStarsCanvas from './Canvases/MovingStarsCanvas';
import MeteorsCanvas from './Canvases/MeteorsCanvas';
import starClouds from './images/StarClouds1.png';

const NightSky = (props) => {    
    return (
        <div className={classes.NightSky}>
            <div className={classes.StarClouds}>
                <img src={starClouds} alt="star clouds"/>
            </div>
            <div className={classes.NightSkyCanvases}>
                <StaticStarsCanvas />
                <MovingStarsCanvas />
                <MeteorsCanvas />
            </div>
        </div>
    );
};

export default NightSky;