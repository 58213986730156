import React from 'react';

import classes from './Banner.module.scss';
import NightSky from './Themes/NightSky/NightSky';
import CherryBlossoms from './Themes/CherryBlossoms/CherryBlossoms';
import PlaneJourney from './Themes/PlaneJourney/PlaneJourney';
import Book from './Themes/Book/Book';

const Banner = (props) => {

    let bannerClasses = [classes.Banner];
    let theme = null;

    switch(props.theme) {
        case 'night-sky':
            theme = <NightSky />;
            break;
        case 'cherry-blossoms':
            theme = <CherryBlossoms />;
            break;
        case 'plane-journey':
            theme = <PlaneJourney />;
            break;
        case 'book':
            theme = <Book />;
            break;
        default:
            bannerClasses.push(classes.Default);
            break;
    }

    let titleTextClasses = [];
    if (props.titleShadow) {
        titleTextClasses.push(classes.TitleShadow);
    }

    // if (props.titleBackground) {
    //     titleTextClasses.push(classes.TitleBackground);
    // }

    return (
        <div className={bannerClasses.join(' ')}>
            <div className={classes.Title}>
                <span className={titleTextClasses.join(' ')}>{props.children}</span>
            </div>
            {theme}
        </div>
    );
};

export default Banner;